/**
 * @Author: Anandp
 * @Date:   2024-04-17 18:40:50
 * @Last Modified by:   Anandp
 * @Last Modified time: 2024-12-23 16:57:55
 */
import PropTypes from "prop-types";
import useMediaQuery from "@mui/material/useMediaQuery";
import { styled } from "@mui/material/styles";
import { MobileNav } from "../mobile-nav";
import { SideNav } from "./side-nav";
import { TopNav } from "./top-nav";
import Typography from "@mui/material/Typography";

import { useMobileNav } from "./use-mobile-nav";
import { Stack, Box } from "@mui/material";
import { useEffect, useState } from "react";
import { createTypography } from "src/theme/base/customTypography";
import * as SldButtons from "src/theme/base/SldButtons";
import surveyAPIService from "src/services/surveyApi";
import { useNavigate, useLocation } from "react-router-dom";
import { useTeam } from "src/contexts/team-context";
import { useSidebar } from 'src/contexts/SidebarContext'; // Import the context hook

const SIDE_NAV_WIDTH = 250;
const SIDE_NAV_MINIMIZED_WIDTH = 80; // Adjust the width for minimized state

const VerticalLayoutRoot = styled("div")(({ theme, navWidth }) => ({
  display: "flex",
  flex: "1 1 auto",
  maxWidth: "100%",
  [theme.breakpoints.up("lg")]: {
    paddingLeft: navWidth,
  },
}));

const VerticalLayoutContainer = styled("div")({
  display: "flex",
  flex: "1 1 auto",
  flexDirection: "column",
  width: "100%",
});

export const VerticalLayout = (props) => {
  const typography = createTypography();
  const { children, sections, navColor } = props;
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  const [topTitle, setTopTitle] = useState('');
  const location = useLocation();

  const mobileNav = useMobileNav();
  const accesssToken = sessionStorage.getItem("accessToken");
  const [showLockScreen, setShowLockScreen] = useState(true);
  const [wiredUpLocked, setWiredUpLocked] = useState(true);
  const [workWithLocked, setWorkWithLocked] = useState(true);
  const { selectedTeamName, clickWithOrg } = useTeam();
  const { isMinimized, handleNavToggle } = useSidebar();

  const Navigate = useNavigate();

  let label = "";
  let shouldRenderButton = false;
  if (showLockScreen && wiredUpLocked && workWithLocked) {
    label = "Complete the surveys";
    shouldRenderButton = false;
  } else if (!showLockScreen && wiredUpLocked && !workWithLocked) {
    label = "Complete Wired-Up Survey";
    shouldRenderButton = true;
  } else if (!showLockScreen && !wiredUpLocked && workWithLocked) {
    label = "Complete Work-With Survey";
    shouldRenderButton = true;
  }

  const handleClick = () => {
    // Navigate based on the label
    switch (label) {
      case "Complete the surveys":
        // Handle the default case or navigate to a common route
        break;
      case "Complete Wired-Up Survey":
        Navigate("/wiredUpSurvey?surveyId=7");
        break;
      case "Complete Work-With Survey":
        Navigate("/workWithSurvey?surveyId=8");
        break;
      default:
      // Handle other cases
    }
  };

  const handleTitleChange = (title) => {
    setTopTitle(title);
  };

  const surveyStatusCheck = async () => {
    try {
      const response = await surveyAPIService.checkSurveyStatus(accesssToken);
      if (response) {
        if (response.data.isLocked === false) {
          setShowLockScreen(false);
        }
        if (response.data.wiredupIsLocked === false) {
          setWiredUpLocked(false);
        }
        if (response.data.workwithIsLocked === false) {
          setWorkWithLocked(false);
        }
        // setIsLoading(false);
      }
    } catch (error) {}
  };
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    surveyStatusCheck();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [pageSubtitle, setPageSubtitle] = useState('');
  useEffect(() => {
    const path = location.pathname;
    const searchParams = new URLSearchParams(location.search);

    let title;
    switch (path) {
      case '/teamMojo':
        title = 'Team Mojo | ';
        break;
      case '/leadershipBrand':
        title = 'Leadership Brand | ';
        break;
      case '/teamDiversity':
        title = 'Team Diversity | ';
        break;
      case '/clickWith':
        if (searchParams.get('clickWithType') === 'team') {
          title = 'Click-With | ';
        } else if (searchParams.get('clickWithType') === 'org') {
          title = 'Click-With Organisation ';
        }
        break;
      case '/teamCompatability':
        title = 'Team Compatibility | ';
        break;
      case '/teamDevelopment':
        title = 'Team Charter | ';
        break;
      default:
        title = 'Default Page Title';
    }
    setPageSubtitle(title);
  }, [location.pathname, location.search]);

  return (
    <>
      <TopNav onMobileNavOpen={mobileNav.handleOpen}
toptitle={topTitle} />
      {lgUp && (
        <SideNav
          color={navColor}
          sections={sections}
          updatetitle={handleTitleChange}
          onToggle={(isMinimized) => handleNavToggle(isMinimized)}
        />
      )}
      {!lgUp && (
        <MobileNav
          color={navColor}
          onClose={mobileNav.handleClose}
          open={mobileNav.open}
          sections={sections}
        />
      )}
      
      {shouldRenderButton && topTitle === "My Personality Profile" ? (
        <Stack direction="row"
justifyContent="space-between">
          <Stack pr={4}
pt={2}>
            <SldButtons.BlackButtonCap
              variant="contained"
              size="medium"
              onClick={handleClick}
            >
              <Typography sx={typography.completeSurveyButton}>
                {label}
              </Typography>
            </SldButtons.BlackButtonCap>
          </Stack>
        </Stack>
      ) : null}
      <VerticalLayoutRoot id="layoutContainer"
navWidth={isMinimized ? SIDE_NAV_MINIMIZED_WIDTH : SIDE_NAV_WIDTH}>
        <VerticalLayoutContainer>
          <Box mr={6}
ml={6}>
            <Typography sx={{ ...typography.contents.bc, color: "#535B66", textTransform: 'capitalize', padding: '10px 0 5px 0' }}>
              {clickWithOrg ? "Click-With Organisation" : `${pageSubtitle}${selectedTeamName}`}
            </Typography>
          </Box>
          <Stack mt={2}>{children}</Stack>
        </VerticalLayoutContainer>
      </VerticalLayoutRoot>
    </>
  );
};

VerticalLayout.propTypes = {
  children: PropTypes.node,
  navColor: PropTypes.oneOf(["blend-in", "discreet", "evident"]),
  sections: PropTypes.array,
};
