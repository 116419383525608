/**
 * @Author: Anandp
 * @Date:   2024-04-17 18:40:50
 * @Last Modified by:   Anandp
 * @Last Modified time: 2024-12-23 15:46:47
 */
import ReactDOM from 'react-dom/client';
import { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { App } from 'src/app';
import mtpDashboardResultsApi from './services/mtpDashboardApi';
import { Buffer } from 'buffer';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { SidebarProvider } from './contexts/SidebarContext'; // Import SidebarProvider
import { JoyrideProvider } from './contexts/JoyrideContext';
// import html2pdf from 'html2pdf.js';
//import useMediaQuery from "@mui/material/useMediaQuery";


const root = ReactDOM.createRoot(document.getElementById('root'));
export async function saveAs(surveyId, type, fileName, json, currentRef, isGp) {
  const isMobile = window.matchMedia("(max-width: 767px)").matches;

  try {
    if (type === "html") {
      const downloadButton = document.getElementById('downloadButton');
      downloadButton && (downloadButton.style.display = 'none')// Hide the button before generating PDF
      const downloadButton1 = document.getElementById('downloadButton1');
      downloadButton1 && (downloadButton1.style.display = 'none') // Hide the button before generating PDF
      const downloadButton2 = document.getElementById('downloadButton2');
      downloadButton2 && (downloadButton2.style.display = 'none') // Hide the button before generating PDF
      const downloadButton3 = document.getElementById('downloadButton3');
      downloadButton3 && (downloadButton3.style.display = 'none') // Hide the button before generating PDF
      const downloadButton4 = document.getElementById('downloadButton4');
      downloadButton4 && (downloadButton4.style.display = 'none') // Hide the button before generating PDF
      const downloadButton5 = document.getElementById('downloadButton5');
      downloadButton4 && (downloadButton4.style.display = 'none') // Hide the button before generating PDF
      const hideCard = document.getElementById('hideCard');
      hideCard && (hideCard.style.display = 'none') // Hide the button before generating PDF
      // const heatText = document.getElementById('heatText');
      // // heatText && (heatText.style.visibility = 'inherit') // Hide the button before generating PDF
      // let scoreTexts = '';
      // if (heatText) {
      //   scoreTexts = document.querySelectorAll(`#heatText .scoreText`);
      //   scoreTexts.forEach(scoreText => {
      //     scoreText.style.visibility = 'visible';
      //   });
      // }
      const editGrid = document.getElementById('editGrid');
      let editButton = '';
      if (editGrid) {
        editButton = document.querySelectorAll(`#editGrid .editButton`);
        editButton.forEach(editButtons => {

          editButtons.style.display = 'none';
        });
      }
      const drawerElement = document.getElementById('drawerr');
      drawerElement && (drawerElement.style.display = 'none');
      const layoutContainer = document.getElementById('layoutContainer');
      layoutContainer && (layoutContainer.style.paddingLeft = 0);
      const topNavWidth = document.getElementById('topNavWidth');
      topNavWidth && (topNavWidth.style.left = 0);
      topNavWidth && (topNavWidth.style.width = '100%');
      const element = currentRef ? currentRef : document.getElementById('root');
      let htmlContent = element.outerHTML;
      const accessToken = sessionStorage.getItem("accessToken");
      const stylesheets = document.styleSheets;
      for (let i = 0; i < stylesheets.length; i++) {
        const stylesheet = stylesheets[i];
        if (stylesheet.href) {
          fetch(stylesheet.href)
            .then(response => response.text())
            // eslint-disable-next-line
            .then(css => {
              htmlContent += `<style>${css}</style>`;
            });
        } else {
          const rules = stylesheet.cssRules;
          if (rules) {
            let css = '';
            for (let j = 0; j < rules.length; j++) {
              css += rules[j].cssText;
            }
            htmlContent += `<style>${css}</style>`;
          }
        }
      }
      const base64Encoded = Buffer.from(htmlContent).toString('base64');
      const staticJson = {
        "html": base64Encoded
      };
      const response = await mtpDashboardResultsApi.postInviteShareLInk(
        staticJson,
        surveyId,
        accessToken
      );
      drawerElement && (drawerElement.style.display = 'block');
      layoutContainer && (layoutContainer.style.paddingLeft = isMobile ? "0px" : "250px");
      topNavWidth && (topNavWidth.style.left = "250px");
      topNavWidth && (topNavWidth.style.width = '');
      //  window.location.reload();
      downloadButton && (downloadButton.style.display = 'flex')
      downloadButton1 && (downloadButton1.style.display = 'flex')
      downloadButton2 && (downloadButton2.style.display = 'flex')
      downloadButton3 && (downloadButton3.style.display = 'flex')
      downloadButton4 && (downloadButton4.style.display = 'block')
      downloadButton5 && (downloadButton5.style.display = 'flex')
      hideCard && (hideCard.style.display = 'block')
      // if (heatText) {
      //   scoreTexts.forEach(scoreText => {
      //     scoreText.style.visibility = 'hidden';
      //     heatText.addEventListener('mouseover', () => {
      //       heatText.style.opacity = 1;
      //       scoreTexts.forEach(scoreText => {
      //         scoreText.style.visibility = 'visible';
      //       });
      //     });

      //     heatText.addEventListener('mouseout', () => {
      //       heatText.style.opacity = 0.8;
      //       scoreTexts.forEach(scoreText => {
      //         scoreText.style.visibility = 'hidden';
      //       });
      //     });
      //   });
      //   window.location.reload();

      // }
      if (editGrid) {
        editButton.forEach(editButtons => {
          editButtons.style.display = 'flex';
        });
      }
      return response;

    } else if (type === "pdf") {

      // Assuming your button has an id of 'downloadButton'
      const downloadButton = document.getElementById('downloadButton');
      downloadButton && (downloadButton.style.display = 'none')// Hide the button before generating PDF
      const downloadButton1 = document.getElementById('downloadButton1');
      downloadButton1 && (downloadButton1.style.display = 'none') // Hide the button before generating PDF
      const downloadButton2 = document.getElementById('downloadButton2');
      downloadButton2 && (downloadButton2.style.display = 'none') // Hide the button before generating PDF
      const downloadButton3 = document.getElementById('downloadButton3');
      downloadButton3 && (downloadButton3.style.display = 'none') // Hide the button before generating PDF
      const downloadButton4 = document.getElementById('downloadButton4');
      downloadButton4 && (downloadButton4.style.display = 'none') // Hide the button before generating PDF
      const downloadButton5 = document.getElementById('downloadButton5');
      downloadButton5 && (downloadButton5.style.display = 'none') // Hide the button before generating PDF
      const hideCard = document.getElementById('hideCard');
      hideCard && (hideCard.style.display = 'none') // Hide the button before generating PDF
      // const editButton = document.getElementById('editButton');
      // editButton && (editButton.style.display = 'none') // Hide the button before generating PDF
      const heatText = document.querySelectorAll('#heatText');
      let scoreTexts = '';
      if (heatText) {
        scoreTexts = document.querySelectorAll(`#heatText .scoreText`);

        heatText.forEach(scoreText => {
          scoreText.style.opacity = 1;
        });
        scoreTexts.forEach(scoreText => {
          scoreText.style.visibility = 'visible';
        });
      }
      const editGrid = document.getElementById('editGrid');
      let editButton = '';
      if (editGrid) {
        editButton = document.querySelectorAll(`#editGrid .editButton`);
        editButton.forEach(editButtons => {

          editButtons.style.display = 'none';
        });
      }
      // sessionStorage.setItem('expandAccordion', 'true');
      const layoutContainer = document.getElementById('layoutContainer');
      layoutContainer && (layoutContainer.style.paddingLeft = 0);
      const topNavWidth = document.getElementById('topNavWidth');
      topNavWidth && (topNavWidth.style.left = 0);
      topNavWidth && (topNavWidth.style.width = '100%');
      const drawerElement = document.getElementById('drawerr');
      drawerElement && (drawerElement.style.display = 'none');
      const waitForImagesToLoad = (element) => {
        const images = element.querySelectorAll('img');
        const promises = [];

        images.forEach((img) => {
          if (!img.complete) {
            // Create a promise that resolves when the image loads
            const imgPromise = new Promise((resolve) => {
              img.onload = resolve;
              img.onerror = resolve; // Resolve even if the image fails to load
            });
            promises.push(imgPromise);
          }
        });

        return Promise.all(promises);
      };

      const captureAndGeneratePDF = () => {
        const rootElement = currentRef ? currentRef : document.getElementById('root');

        if (window.location.pathname.includes('/prepSheets')) {
          waitForImagesToLoad(rootElement).then(() => {
            const elements = document.querySelectorAll(".MuiAccordion-root ");
            elements.forEach(element => {

              element.classList.add("downloadacc");
            });
            const downloadPrint = document.querySelectorAll(".downloadPrint");
            downloadPrint.forEach(element => {
              element.classList.add('cc-grid');

            });
            const PaperShadowPrint = document.querySelectorAll(".PaperShadowPrint");
            PaperShadowPrint.forEach(element => {
              element.classList.add('cc-paper');

            });
            const elementss = document.querySelectorAll(".MuiCardHeader-action");
            elementss.forEach(element => {
              element.classList.add('teamDownload');

            });

            const elementsd = document.querySelectorAll(".downloadContent");
            elementsd.forEach(element => {

              element.classList.add("downloaded");

            });
            const elementsdh = document.querySelectorAll(".dhide");
            elementsdh.forEach(element => {
              element.classList.add("downloaded");

            });

            const topNavDiv = document.getElementById('topNavWidth');
  
            // Dynamically add a class
            topNavDiv.classList.add('hidtopnam');
            // html2pdf()
            //   .from(rootElement)
            //   .set({
            //     margin: 0, // Adjust the margins as needed
            //     filename: 'LBS_report.pdf',
            //     html2canvas: { scale: 2 },
            //     jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
            //     pagebreak: { mode: ['avoid-all', 'css'] }
            //   })
            //   .save();
            // const pdf = new jsPDF('p', 'mm', 'a4');
            // const pageHeight = pdf.internal.pageSize.getHeight();
            // const pageWidth = pdf.internal.pageSize.getWidth();

            // // Configure html2canvas options
            // const options = {
            //   scale: 1.5, // Lower scale to reduce the resolution and file size (1.5 instead of 2)
            //   useCORS: true, // Handle cross-origin images
            //   logging: false, // Disable logging for performance
            // };

            // html2canvas(rootElement, options).then((canvas) => {
            //   // Convert canvas to image (lower quality for smaller file size)
            //   const imgData = canvas.toDataURL('image/jpeg', 0.5); // Change to JPEG and reduce quality to 0.5

            //   const imgWidth = pageWidth;
            //   const imgHeight = (canvas.height * imgWidth) / canvas.width;

            //   let position = 0;
            //   let heightLeft = imgHeight;

            //   // Add the first page
            //   pdf.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight); 
            //   heightLeft -= pageHeight;

            //   // Loop over remaining content and add new pages
            //   while (heightLeft > 0) {
            //     position = heightLeft - imgHeight;
            //     pdf.addPage();
            //     pdf.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight); // Use JPEG format
            //     heightLeft -= pageHeight;
            //   }

            //   // Save the compressed PDF
            //   pdf.save('dashboard.pdf');
            // });
            window.print();
            // window.location.reload();

            topNavWidth && (topNavWidth.style.left = "250px");
            topNavWidth && (topNavWidth.style.width = '');
            drawerElement && (drawerElement.style.display = 'block');
            layoutContainer && (layoutContainer.style.paddingLeft = isMobile ? "0px" : "250px");
            downloadButton && (downloadButton.style.display = 'flex')
            downloadButton1 && (downloadButton1.style.display = 'flex')
            downloadButton2 && (downloadButton2.style.display = 'flex')
            downloadButton3 && (downloadButton3.style.display = 'flex')
            downloadButton4 && (downloadButton4.style.display = 'block')
            downloadButton5 && (downloadButton5.style.display = 'flex')
            hideCard && (hideCard.style.display = 'block')
            const element1 = document.querySelectorAll(".MuiAccordion-root ");
            element1.forEach(element => {
              element.classList.remove("downloadacc");
            });
            const elements2 = document.querySelectorAll(".MuiCardHeader-action");
            elements2.forEach(element => {
              element.classList.remove('teamDownload');
            });
            const elementsdd = document.querySelectorAll(".downloadContent");
            elementsdd.forEach(element => {
              element.classList.remove("downloaded");
            });
            const elementsdh1 = document.querySelectorAll(".dhide");
            elementsdh1.forEach(element => {
              element.classList.remove("downloaded");

            });
            const downloadPrints = document.querySelectorAll(".downloadPrint");
            downloadPrints.forEach(element => {
              element.classList.remove('cc-grid');

            });
            const PaperShadowPrints = document.querySelectorAll(".PaperShadowPrint");
            PaperShadowPrints.forEach(element => {
              element.classList.remove('cc-paper');

            });

            

            
            const topNavDiv1 = document.getElementById('topNavWidth');
  
            // Dynamically add a class
            topNavDiv1.classList.remove('hidtopnam');
            if (heatText.length > 0) {
              scoreTexts.forEach(scoreText => {
                scoreText.style.visibility = 'hidden';
                heatText.forEach(scoreTextss => {
                  scoreTextss.addEventListener('mouseover', () => {
                    scoreTextss.style.opacity = 1;
                    scoreTexts.forEach(scoreText => {
                      scoreText.style.visibility = 'visible';
                    });
                  });

                  scoreTextss.addEventListener('mouseout', () => {
                    scoreTextss.style.opacity = 0.8;
                    scoreTexts.forEach(scoreText => {
                      scoreText.style.visibility = 'hidden';
                    });
                  });
                })
              });
              // window.location.reload();

            }
            if (editGrid) {
              editButton.forEach(editButtons => {
                editButtons.style.display = 'flex';
              });
            }
          });
        } else {
          // Wait for all images in the rootElement to load
          waitForImagesToLoad(rootElement).then(() => {
            const elements = document.querySelectorAll(".MuiAccordion-root ");
            elements.forEach(element => {

              element.classList.add("downloadacc");
            });

            
            
            const elementss = document.querySelectorAll(".MuiCardHeader-action");
            elementss.forEach(element => {
              element.classList.add('teamDownload');

            });
            const elementsd = document.querySelectorAll(".downloadContent");
            elementsd.forEach(element => {

              element.classList.add("downloaded");

            });
            const elementsdh = document.querySelectorAll(".dhide");
            elementsdh.forEach(element => {
              element.classList.add("downloaded");

            });

  
            // Dynamically add a class
            html2canvas(rootElement, {
              scale: 2, // You may need to adjust the scale for better quality
              logging: true, // Enable logging to troubleshoot any issues
              scrollX: 0, // Set scrollX to 0 to capture entire content
              scrollY: 0, // Set scrollY to 0 to capture entire content
              width: document.documentElement.scrollWidth,
              height: document.documentElement.scrollHeight,
              useCORS: true // Enable CORS if needed
            }).then((canvas) => {
              const imgData = canvas.toDataURL('image/jpeg', 1);
              const pdf = new jsPDF({
                orientation: canvas.width > canvas.height ? 'landscape' : 'portrait',
                unit: 'px',
                format: [canvas.width, canvas.height]
              });

              const imgWidth = pdf.internal.pageSize.getWidth();
              const imgHeight = pdf.internal.pageSize.getHeight();

              pdf.addImage(imgData, "JPEG", 0, 0, imgWidth, imgHeight);
              pdf.save(fileName ? `${fileName}.pdf` : 'app.pdf');


              // window.location.reload();
              topNavWidth && (topNavWidth.style.left = "250px");
              topNavWidth && (topNavWidth.style.width = '');
              drawerElement && (drawerElement.style.display = 'block');
              layoutContainer && (layoutContainer.style.paddingLeft = isMobile ? "0px" : "250px");
              downloadButton && (downloadButton.style.display = 'flex')
              downloadButton1 && (downloadButton1.style.display = 'flex')
              downloadButton2 && (downloadButton2.style.display = 'flex')
              downloadButton3 && (downloadButton3.style.display = 'flex')
              downloadButton4 && (downloadButton4.style.display = 'block')
              downloadButton5 && (downloadButton5.style.display = 'flex')
              hideCard && (hideCard.style.display = 'block')
              const element1 = document.querySelectorAll(".MuiAccordion-root ");
              element1.forEach(element => {
                element.classList.remove("downloadacc");
              });
              const elements2 = document.querySelectorAll(".MuiCardHeader-action");
              elements2.forEach(element => {
                element.classList.remove('teamDownload');
              });
              const elementsdd = document.querySelectorAll(".downloadContent");
              elementsdd.forEach(element => {
                element.classList.remove("downloaded");
              });
              const elementsdh1 = document.querySelectorAll(".dhide");
              elementsdh1.forEach(element => {
                element.classList.remove("downloaded");
  
              });
              if (heatText.length > 0) {
                scoreTexts.forEach(scoreText => {
                  scoreText.style.visibility = 'hidden';
                  heatText.forEach(scoreTextss => {
                    scoreTextss.addEventListener('mouseover', () => {
                      scoreTextss.style.opacity = 1;
                      scoreTexts.forEach(scoreText => {
                        scoreText.style.visibility = 'visible';
                      });
                    });

                    scoreTextss.addEventListener('mouseout', () => {
                      scoreTextss.style.opacity = 0.8;
                      scoreTexts.forEach(scoreText => {
                        scoreText.style.visibility = 'hidden';
                      });
                    });
                  })
                });
                // window.location.reload();

              }
              if (editGrid) {
                editButton.forEach(editButtons => {
                  editButtons.style.display = 'flex';
                });
              }
            });
          });
        }

      };

      if (isGp) {
        // Introduce a 3-second delay for content to load before capturing the element
        // setTimeout(() => {
        captureAndGeneratePDF();
        // }, 3000);
      } else {
        captureAndGeneratePDF(); // Capture and generate PDF immediately if isGP is false
      }



      // html2canvas(rootElement, {
      //   scrollX: 0,
      //   scrollY: 0,
      //   windowWidth: document.documentElement.scrollWidth,
      //   windowHeight: document.documentElement.scrollHeight
      // }).then(canvas => {
      //   const imgData = canvas.toDataURL('image/jpeg', 1); // Adjust image quality as needed
      //   const pdf = new jsPDF('l', 'px', [canvas.width, canvas.height]);
      //   const imgProps = pdf.getImageProperties(imgData);
      //   const pdfWidth = pdf.internal.pageSize.getWidth();
      //   const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      //   pdf.addImage(imgData, 'JPEG', 0, 0, pdfWidth, pdfHeight);
      //   pdf.save(fileName ? `${fileName}.pdf` : 'app.pdf');
      // });

      // old code with high quality
      // const rootElement = document.getElementById('root');
      // html2canvas(rootElement, { scrollX: 0, scrollY: 0, windowWidth: document.documentElement.scrollWidth, windowHeight: document.documentElement.scrollHeight }).then(canvas => {
      //   const imgData = canvas.toDataURL('image/png', 0.7);
      //   const pdf = new jsPDF('l', 'px', [canvas.width, canvas.height]);
      //   const imgProps = pdf.getImageProperties(imgData);
      //   const pdfWidth = pdf.internal.pageSize.getWidth();
      //   const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      //   pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      //   pdf.save(fileName ? `${fileName}.pdf` : 'app.pdf');
      // });

      return false;
    } else if (type === "csv") {
      const convertToCsv = () => {
        const replacer = (key, value) => (value === null ? '' : value);
        const header = Object.keys(json[0]);
        let csv = json.map((row) =>
          header
            .map((fieldName) => JSON.stringify(row[fieldName], replacer))
            .join(',')
        );
        csv.unshift(header.join(','));
        csv = csv.join('\r\n');
        return csv;
      };
      const csv = convertToCsv();
      const csvData = new Blob([csv], { type: 'text/csv' });
      const csvUrl = URL.createObjectURL(csvData);
      const hiddenElement = document.createElement('a');
      hiddenElement.href = csvUrl;
      hiddenElement.target = '_blank';
      hiddenElement.download = fileName ? `${fileName}.csv` : 'app.csv';
      hiddenElement.click();

    }

  } catch (error) {
    console.log(error.message);
  }
  // const blob = new Blob([htmlContent], { type: 'text/html' });
  // const url = window.URL.createObjectURL(blob);
  // const a = document.createElement('a');
  // a.href = url;
  // a.download = 'MtpDashboard.html';
  // a.click();
  // window.URL.revokeObjectURL(url);

}

root.render(
  <HelmetProvider>
    <BrowserRouter>
      <JoyrideProvider>
        <SidebarProvider>
          <Suspense>
            <App />
          </Suspense>
        </SidebarProvider>
      </JoyrideProvider>
    </BrowserRouter>
  </HelmetProvider>
);
