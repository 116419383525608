/**
 * @Author: Anandp
 * @Date:   2024-04-17 18:40:49
 * @Last Modified by:   Anandp
 * @Last Modified time: 2024-12-23 15:48:09
 */
import React, { createContext, useContext, useState } from "react";

const TeamContext = createContext();

export const TeamProvider = ({ children }) => {
  const [selectedTeamId, setSelectedTeamId] = useState([]);
  const [selectedInviteId, setSelectedInviteIds] = useState([]);
  const [dashboardStatusState, setDashboardStatusState] = useState(false);
  const [hrAnalyticsStatusState, setHrAnalyticsStatusState] = useState(false);
  const [applyTeams, setApplyTeams] = useState(false);
  const [teamInfo, setTeamInfo] = useState([]);
  const [orgLevel, setOrgLevel] = useState(false);
  const [orgName, setOrgName] = useState(null);

  const setTeamId = (teamId, teamName, divisionName) => {
    // setSelectedTeamId([]);
    // setTeamInfo([]);
    const stringifiedTeamId = String(teamId); // Convert teamId to string for consistent comparison
  
    // Check if the teamId already exists in the selectedTeamId state
    setSelectedTeamId((prevIds) => {
      const updatedIds = new Set(prevIds.map(id => String(id))); // Ensure all IDs are strings
      if (updatedIds.has(stringifiedTeamId)) {
        // If teamId already exists, return the previous state (no changes)
        return prevIds;
      } else {
        // If teamId does not exist, add it to the state
        return [...prevIds, teamId];
      }
    });
  
    // Check if the teamId already exists in the teamInfo state
    setTeamInfo((prevTeamInfo) => {
      const teamExists = prevTeamInfo.some((team) => String(team.teamId) === stringifiedTeamId); // Convert teamId to string
      if (teamExists) {
        // If teamId already exists, return the previous state (no changes)
        return prevTeamInfo;
      } else {
        // If teamId does not exist, add the new team info
        return [
          ...prevTeamInfo,
          { teamId: stringifiedTeamId, teamName, divisionName }, // Convert teamId to string
        ];
      }
    });
  };
  const setSelectedInviteId = (inviteID,teamId, pulse) => {
    setSelectedInviteIds((prevIds) => [...prevIds, inviteID]);
    // setTeamInfo((prevTeamInfo) => [
    //   ...prevTeamInfo,
    //   { teamId, inviteID, pulse },
    // ]);
  };
  const setDashboardStatus = (status,teamId, pulse) => {
    // setDashboardStatusState(status);
    setDashboardStatusState(status);
    // setTeamInfo((prevTeamInfo) => [
    //   ...prevTeamInfo,
    //   { teamId, inviteID, pulse },
    // ]);
  };
  const setHrAnalyticsStatus = (status,teamId, pulse) => {
    // setDashboardStatusState(status);
    setHrAnalyticsStatusState(status);
    // setTeamInfo((prevTeamInfo) => [
    //   ...prevTeamInfo,
    //   { teamId, inviteID, pulse },
    // ]);
  };
  const removeTeamId = (teamId) => {
    setSelectedTeamId((prevIds) => prevIds.filter((id) => id !== teamId));
    setTeamInfo((prevTeamInfo) =>
      prevTeamInfo.filter((team) => team.teamId !== teamId)
    );
  };

  const removeAllTeamIds = () => {
    setSelectedTeamId([]);
    setTeamInfo([]);
  };

  const settingOrgLevelStateTrue = () => {
    setOrgLevel(true);
  };

  const settingOrgLevelStateFalse = () => {
    setOrgLevel(false);
  };

  const settingOrgName = (name) => {
    setOrgName(name);
  };

  const applySelectedTeams = () => {
    setApplyTeams(true);
  };

  const unApplySelectedTeams = () => {
    setApplyTeams(false);
  };

  return (
    <TeamContext.Provider
      value={{
        selectedTeamId,
        setTeamId,
        removeTeamId,
        applyTeams,
        teamInfo,
        setTeamInfo,
        applySelectedTeams,
        unApplySelectedTeams,
        removeAllTeamIds,
        settingOrgLevelStateTrue,
        settingOrgLevelStateFalse,
        orgLevel,
        settingOrgName,
        orgName,
        selectedInviteId,
        setSelectedInviteId,
        dashboardStatusState,
        setDashboardStatus,
        hrAnalyticsStatusState,
        setHrAnalyticsStatus
      }}
    >
      {children}
    </TeamContext.Provider>
  );
};

export const useTeam = () => {
  return useContext(TeamContext);
};
