/**
 * @Author: Anandp
 * @Date:   2024-04-17 18:40:50
 * @Last Modified by:   Anandp
 * @Last Modified time: 2024-12-23 22:04:18
 */
import axios from "axios";
import { Url, siteConstant } from "src/config";
import CryptoJS from 'crypto-js';
/**
 * Login API Service
 * @date 6/28/2023 - 3:13:52 AM
 * pass credentials
 */

const loginApiService = {
  checkLogin: async () => {
    try {
      const response = await axios.post(`${Url}/module/profile`);
      return response.data;
    } catch (error) {
      console.log(error.message);
    }
  },
  userLogout: async () => {
    try {
      const accessToken = sessionStorage.getItem(siteConstant.AUTH_TOKEN_STORAGE_KEY);
      const response = await axios.post(`${Url}/auth/logout`, {}, {
        headers: {
          "access-token": accessToken,
        },
      });    
      return response.data;
    } catch (error) {
      console.log(error.message);
    }
  },
  getProvider: async (data) => {
    //Get sso-provider
    try {
      const response = await axios.post(`${Url}/auth/sso-provider`, data);
      return response.data;
    } catch (error) {
      console.log(error.message);
    }
  },
  getDashboardIds: async (accessToken, id) => {
    try {
      const response = await axios.get(`${Url}/customer/getdashboardperms?userId=${id}`,
        {
          headers: {
            "access-token": accessToken,
          },
        }
      );
      const numberArray = response?.data?.dashIds?.map(Number);
      const encrypted = CryptoJS.AES.encrypt(JSON.stringify(numberArray), id.toString()).toString();
      // sessionStorage.setItem("dashboardPerms", JSON.stringify(response.data.dashIds));
      sessionStorage.setItem("dashboardPerms", encrypted);
      window.dispatchEvent(new Event('storage'));
      return response.data;
    } catch (error) {
      console.log(error.message);
    }
  },
  getSurveyIds: async (accessToken, id) => {
    try {
      const response = await axios.get(`${Url}/customer/getsurveyperms?userId=${id}`,
        {
          headers: {
            "access-token": accessToken,
          },
        }
      );
      
      const surveyIds = response?.data?.surveyIds ? response?.data?.surveyIds : response?.data?.SurveyIds
      const numberArray = surveyIds.map(Number);
      const encrypted = CryptoJS.AES.encrypt(JSON.stringify(numberArray), id.toString()).toString();
      // sessionStorage.setItem("surveyPerms", JSON.stringify(response.data.surveyIds));
      sessionStorage.setItem("surveyPerms", encrypted);
      window.dispatchEvent(new Event('storage'));
      return response.data;
    } catch (error) {
      console.log(error.message);
    }
  },
  acceptUserConsent: async (data, accessToken) => {
    //Accept user consent
    try {
      const response = await axios.post(`${Url}/auth/user-consent`, data, {
        headers: {
          "access-token": accessToken,
        },
      });
      const token = response.headers["x-access-token"];
      sessionStorage.setItem(siteConstant.AUTH_TOKEN_STORAGE_KEY, token);
      return;
    } catch (error) {
      console.log(error.message);
    }
  },
};


export default loginApiService;
