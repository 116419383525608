/**
 * @Author: Anandp
 * @Date:   2024-04-17 18:40:50
 * @Last Modified by:   Anandp
 * @Last Modified time: 2024-12-25 18:09:23
 */
// joyride
// eslint-disable-next-line  
import Joyride, { STATUS, ACTIONS } from 'react-joyride';
import React, { useState, useEffect, useContext } from 'react';
import CustomTooltip from 'src/components/CustomTooltipJoyridenew';

import 'src/components/joyride.css';
//import { ReactComponent as NavigateStart } from "src/icons/untitled-ui/NavigateStart.svg";
import CryptoJS from 'crypto-js';
// joyride
import PropTypes from "prop-types";
import Menu01Icon from "@untitled-ui/icons-react/build/esm/Menu01";
import { Box, Divider } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import SvgIcon from "@mui/material/SvgIcon";
import useMediaQuery from "@mui/material/useMediaQuery";
import Typography from "@mui/material/Typography";
import { AccountButton } from "../account-button";
import { SearchButton } from "../search-button";
import { createTypography } from "src/theme/base/customTypography";
import { paths } from "src/paths";
import { useUser } from "src/hooks/use-user";

// import { saveAs } from "../../../index";
import * as SldButtons from "src/theme/base/SldButtons";
import { ReactComponent as DownloadPageIcon } from "src/icons/untitled-ui/downloadPage.svg";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import LanguageIcon from "@mui/icons-material/Language";
import { useNavigate, useLocation } from "react-router-dom";
import { DownloadPagePopUp } from "../popUps/downloadPagePopUp";
import { useSidebar } from 'src/contexts/SidebarContext'; // Import the context hook
import customerApiService from "src/services/customerApi";
import loginApiService from "src/services/loginVerifyAPI";
import surveyAPIService from 'src/services/surveyApi';
import myLeadershipApi from 'src/services/myLeadershipApi';
import executiveHealthResultsApi from 'src/services/executiveHealthApi';
import { JoyrideContext } from 'src/contexts/JoyrideContext';
import { getJoyrideSteps } from 'src/components/joyrideStepsLSdb';


const TOP_NAV_HEIGHT = 85;
const SIDE_NAV_WIDTH = 250;
const SIDE_NAV_MINIMIZED_WIDTH = 80; // Adjust the width for minimized state

export const TopNav = (props) => {

  // eslint-disable-next-line
  const [inviteId, setInviteId] = useState(null);
 
  // eslint-disable-next-line
  const { joyrideSteps, resetJoyride, addSteps, removeSteps, setOpenAddMembersForm, openAddMembersForm, setOpenRequestForm, openRequestForm, } = useContext(JoyrideContext); // Access context

  const navigate = useNavigate();
  const user = useUser();
  const accessToken = sessionStorage.getItem("accessToken");
  // eslint-disable-next-line
  const [isLoadingml, setIsLoadingml] = useState(false);
  // eslint-disable-next-line
  const [isDataLoadedml, setIsDataLoadedml] = useState(false);
  const location = useLocation(); // Get current location
  const { isMinimized } = useSidebar();
  const [isAccClassAdded, setIsAccClassAdded] = useState(false);
  const [isBodyClassAdded, setIsBodyClassAdded] = useState(false);
  // const [wiredUpStatus, setWiredUpStatus] = useState(true);
  // const [workWithStatus, setWorkWithStatus] = useState(true);
  const [isTitledClassAdded, setIsTitledClassAdded] = useState(false);
  const [isDownoadeContent, setIsDownoadeContent] = useState(false);
  // const [shouldRerun, setShouldRerun] = useState(false); 

  const navigateToExplore = () => {
    navigate("/explorerPage");
  };
  // eslint-disable-next-line  
  const getPermissions = async () => {
    await customerApiService.getJoyride(accessToken, user.id);
    await loginApiService.getDashboardIds(accessToken, user.id);
    await loginApiService.getSurveyIds(accessToken, user.id);

  };
  const typography = createTypography();
  const { onMobileNavOpen, toptitle, ...other } = props;
  const [isLoading
    // , setIsLoading
  ] = useState(false);
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  // const getShareLink = async (type) => {
  //   try {
  //     setIsLoading(true);
  //     const response = await saveAs(0, type, toptitle);
  //     setIsLoading(response);
  //   } catch (error) {
  //   }
  // };
  const [isPopupOpen, setPopupOpen] = useState(false);
  const openPopup = () => {
    setPopupOpen(true);
    setIsAccClassAdded(true);
    setIsTitledClassAdded(true);
    setIsDownoadeContent(true);
    setIsBodyClassAdded(true);

  };
  const closePopup = () => {
    setPopupOpen(false);
    setIsAccClassAdded(false);
    setIsTitledClassAdded(false);
    setIsDownoadeContent(false);
    setIsBodyClassAdded(false);

  };
  const [isJoyrideRunning, setIsJoyrideRunning] = useState(false);
  const isMobile = useMediaQuery("(max-width:600px)");
  const [run, setRun] = useState(false);
  // eslint-disable-next-line
  const [stepIndex, setStepIndex] = useState(0);
  const [disableScroll, setDisableScroll] = useState(false);
  const getTitle = (path) => {
    switch (path) {
      case paths.dashboard.leadself.index:
        return "My Personality Profile";
      case paths.dashboard.mymojo.index:
        return "My Mojo";
      case paths.dashboard.myLeadership.index:
        return "My Leadership";
      case paths.dashboard.myExecutiveHealth.index:
        return "My Executive Health";
      case paths.dashboard.myPulseTrends.index:
        return "My Pulse Trends";
      case paths.dashboard.islProgrammes.goalsAndPulses:
        return "My Goals & Pulses";
      case paths.dashboard.conversationWithBoss.index:
        return "Conversation With Boss";
      case paths.dashboard.coachesCorner.index:
        return "Coaches Corner";
      case paths.dashboard.leadselfSettings.index:
        return "Lead Self Settings";

      default:
        return "Dashboard";
    }
  };

  const title = getTitle(location.pathname); // Get title based on path


  const isLeadSelfSettingsPage = location.pathname === paths.dashboard.leadselfSettings.index;   // Check if current path is lead self settings
  // joyride
  // eslint-disable-next-line

  const [steps, setSteps] = useState([]);
  const [triggered, setTriggered] = useState(true);
  useEffect(() => {
    // Clear existing steps
    removeSteps();

    // Retrieve new steps based on the current pathname
    const newSteps = getJoyrideSteps(location.pathname);

    // If there are new steps, add them and update the component's steps
    if (newSteps && newSteps.length > 0) {
      addSteps(newSteps);
      // No need to use setSteps(newSteps) immediately here
    } else {
      // Clear the component's steps if there are no new steps
      setSteps([]);
    }
  }, [location.pathname, addSteps, removeSteps]);



  useEffect(() => {
    const joyrideSteps = getJoyrideSteps(location.pathname);
    if (joyrideSteps.length > 0) {
      setSteps(joyrideSteps);
    } else {
      // Optionally handle the case where joyrideSteps is empty
      setSteps([]);
    }

  }, [joyrideSteps, location.pathname,]);
  // eslint-disable-next-line 
  const handleClickStart = (event, callback) => {
    event.preventDefault();
    setRun(false);
    resetJoyride();

    window.scrollTo({ top: 0, behavior: 'smooth' });

    setTimeout(() => {
      const filteredSteps = steps.filter((step) => document.querySelector(step.target));

      if (filteredSteps.length > 0) {
        addSteps(filteredSteps);
        setRun(true);
      } else {
      }

      if (callback && typeof callback === 'function') {
        callback();
      }
    }, 500);
  };
  const dashboardPermsEn = sessionStorage.getItem("dashboardPerms");
  const dashboardPerms = dashboardPermsEn && JSON.parse(CryptoJS.AES.decrypt(dashboardPermsEn, user.id?.toString())?.toString(CryptoJS.enc.Utf8)).map(Number);
  const currentPaths = window.location.pathname;
  useEffect(() => {
    const checkAndTriggerJoyride = async () => {
      const dashboardDiv = document.getElementById('topNavWidth'); // Select the div  
      if (steps.length > 0 && triggered) {
        const joyrideStatusEn = sessionStorage.getItem("joyrideStatus");
        const joyrideStatus = CryptoJS.AES.decrypt(joyrideStatusEn, user.id?.toString())?.toString(CryptoJS.enc.Utf8);
        const surveyPermsEn = sessionStorage.getItem("surveyPerms");
        const surveyPerms = JSON.parse(CryptoJS.AES.decrypt(surveyPermsEn, user.id?.toString())?.toString(CryptoJS.enc.Utf8)).map(Number);
        const currentPath = window.location.pathname;

        let htw = false; // default value

        // Set htw based on the current URL path
        if (currentPath === '/leadSelf') {
          dashboardDiv.classList.add('no-permision');
          let LeadSelfLock, WorWithLock;

          // LeadSelfLock = wiredUpStatus;
          // WorWithLock = workWithStatus;
          LeadSelfLock = await getLeadselfLockStatus();
          WorWithLock = await getWorkwithStatus();
          // Check for permission 1 or 2
          if (surveyPerms && (surveyPerms.includes(7) && surveyPerms.includes(8)) && dashboardPerms && (dashboardPerms.includes(1) && dashboardPerms.includes(2))) {



            htw = !WorWithLock && !LeadSelfLock && JSON.parse(joyrideStatus).leadSelf === 0;
            dashboardDiv.classList.remove('no-permision');
            if (WorWithLock || LeadSelfLock) { dashboardDiv.classList.add('no-permision'); }

          }
          if (surveyPerms && (surveyPerms.includes(7) && surveyPerms.includes(8)) && dashboardPerms && (dashboardPerms.includes(1) && dashboardPerms.includes(2))) {

            htw = !WorWithLock && !LeadSelfLock && JSON.parse(joyrideStatus).leadSelf === 0;
            dashboardDiv.classList.remove('no-permision');
            if (WorWithLock || LeadSelfLock) { dashboardDiv.classList.add('no-permision'); }

          }

          else if (dashboardPerms && dashboardPerms.includes(1)) {
            htw = !WorWithLock && !LeadSelfLock && JSON.parse(joyrideStatus).leadSelf === 1;
            dashboardDiv.classList.add('no-htb');
            dashboardDiv.classList.remove('no-permision')
          }
          else if (!surveyPerms && surveyPerms.includes(7) && dashboardPerms && dashboardPerms.includes(1)) {
            htw = !WorWithLock && !LeadSelfLock && JSON.parse(joyrideStatus).leadSelf === 1;
            dashboardDiv.classList.add('no-permision')
          }
          else if (dashboardPerms && dashboardPerms.includes(2)) {

            WorWithLock = await getWorkwithStatus();
            htw = !WorWithLock && !LeadSelfLock && JSON.parse(joyrideStatus).leadSelf === 1;
            dashboardDiv.classList.add('no-htb');
            dashboardDiv.classList.remove('no-permision')
          }
          else if (!surveyPerms && surveyPerms.includes(8) && dashboardPerms && dashboardPerms.includes(2)) {
            htw = !WorWithLock && !LeadSelfLock && JSON.parse(joyrideStatus).leadSelf === 1;
            dashboardDiv.classList.add('no-permision')
          }
          else {
            dashboardDiv.classList.add('no-permision');
          }
        } else if (currentPath === '/myLeadership') {

          const dataLoaded = await getMyLeadershipDashboardData(inviteId);
          setIsDataLoadedml(dataLoaded); // Update state based on data load
          dashboardDiv.classList.add('no-permision');
          // let myLeadershipLock = await getMyLeadershipStatus();

          // htw = dashboardPerms && dashboardPerms.includes(6) && JSON.parse(joyrideStatus).myLeadership === 0;
          const myLeadershipLock = await getMyLeadershipStatus();
          if (dataLoaded) {
            if (dashboardPerms && dashboardPerms.includes(6)) {


              htw = dataLoaded && !myLeadershipLock && JSON.parse(joyrideStatus).myLeadership === 0;
              dashboardDiv.classList.add('no-permision');

              if (JSON.parse(joyrideStatus).myLeadership === 1 && !myLeadershipLock) {
                dashboardDiv.classList.remove('no-permision');

                dashboardDiv.classList.remove('no-htb');

              }

            }
          }
          else {
            dashboardDiv.classList.add('no-permision');
          }
        } else if (currentPath === '/myExecutiveHealth') {
          dashboardDiv.classList.add('no-permision');
          // let executiveHealthLock = await getExecutiveHealthStatus();

          // htw = dashboardPerms && dashboardPerms.includes(5) && JSON.parse(joyrideStatus).myExecutiveHealth === 0;
          const executiveHealthLock = await getExecutiveHealthStatus();
          const executiveHealthData = await getExecutiveHealthDataStatus();
          if (dashboardPerms && dashboardPerms.includes(5)) {

            dashboardDiv.classList.remove('no-permision');
            htw = executiveHealthData && !executiveHealthLock && JSON.parse(joyrideStatus).myExecutiveHealth === 0;

            if (executiveHealthLock) { dashboardDiv.classList.add('no-permision'); }
          }
          else {
            dashboardDiv.classList.add('no-permision');
          }
        }

        else if (currentPath === '/leadSelfSettings') {
          dashboardDiv.classList.remove('no-permision');
          htw = JSON.parse(joyrideStatus).leadSelfSettings === 0;
        }

        if (htw === true) {

          handleClickStart(new Event('click'));
          dashboardDiv.classList.remove('no-permision');
          dashboardDiv.classList.remove('no-htb');
        }


        setTriggered(false);
      }

    };

    checkAndTriggerJoyride();

    // eslint-disable-next-line 
  }, [steps, window.location.pathname]);

  useEffect(() => {
    setTriggered(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname])

  // Add dependencies here
  const calculateScrollOffset = () => {
    switch (location.pathname) {
      case '/leadSelf':
        return 100;
      case '/leadSelfSettings':
        return 100;
      case '/myLeadership':
        return 150;
      case '/myExecutiveHealth':
        return 200;
      default:
        return 0;
    }
  };

  const currentScrollOffset = calculateScrollOffset();
  const handleJoyrideCallback = (data) => {
    // eslint-disable-next-line
    const { status, index, action, step } = data;

    if (status === 'running') {
      setIsJoyrideRunning(true);
    } else {
      setIsJoyrideRunning(false);
    }
    // eslint-disable-next-line
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    // Log steps for debugging


    if (status === 'finished' || status === 'skipped') {

      setRun(false);

    }

    // Handle scrolling behavior
    const scrollBehavior = {
      '/myExecutiveHealth': {
        enableScrollSteps: [1, 2, 3],
        disableScrollSteps: [0],
      },
      '/leadSelf': {
        enableScrollSteps: [1, 2, 3, 4],
        disableScrollSteps: [0,],
      },
      '/myLeadership': {
        enableScrollSteps: [1, 2, 3, 5, 4],
      },
      '/leadSelfSettings': {
        enableScrollSteps: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
        disableScrollSteps: [0,],
      },
      // Add more URLs and their respective steps as needed
    };

    const currentScrollBehavior = scrollBehavior[location.pathname] || {};

    // Enable or disable scroll based on current step
    if (currentScrollBehavior.enableScrollSteps?.includes(index)) {
      setDisableScroll(false);
    } else if (currentScrollBehavior.disableScrollSteps?.includes(index)) {
      setDisableScroll(true);
    } else {
      setDisableScroll(false); // Default behavior if not specified
    }
  };
  useEffect(() => {
    if (isJoyrideRunning) {
      document.body.classList.add('joyride-active');
    } else {
      document.body.classList.remove('joyride-active');
    }
  }, [isJoyrideRunning]);
  // const handleJoyrideCallback = useCallback((data) => {

  // },[setOpenAddMembersForm, setShouldRerun,location.pathname]);
  ;  // joyride
  /* useEffect(() => {
    if (openAddMembersForm && shouldRerun) {
      setRun(true); // Re-run Joyride to recognize elements inside the popup
      setShouldRerun(false); // Reset rerun flag after Joyride restarts
    }
    // eslint-disable-next-line
  }, [openAddMembersForm, shouldRerun]);
 */

  useEffect(() => {
    const elements = document.querySelectorAll(".dhide");
    elements.forEach(element => {
      if (isTitledClassAdded) {
        element.classList.add("downloaded");
      } else {
        element.classList.remove("downloaded");
      }
    });
  }, [isTitledClassAdded]);
  useEffect(() => {
    // Remove classes first to avoid duplication
    document.body.classList.remove('leadSelf-url', 'leadSelfSettings');

    if (location.pathname === '/leadSelf') {
      document.body.classList.add('leadSelf-url');
    } else if (location.pathname === '/leadSelfSettings') {
      document.body.classList.add('leadSelfSettings');
    }
  }, [location]);
  useEffect(() => {
    const elements = document.querySelectorAll(".downloadContent");
    elements.forEach(element => {
      if (isDownoadeContent) {
        element.classList.add("downloaded");
      } else {
        element.classList.remove("downloaded");
      }
    });
  }, [isDownoadeContent]);

  useEffect(() => {
    const elements = document.querySelectorAll(".MuiAccordion-root ");
    elements.forEach(element => {
      if (isAccClassAdded) {
        element.classList.add("downloadacc");
      } else {
        element.classList.remove("downloadacc");
      }
    });
  }, [isAccClassAdded]);
  useEffect(() => {
    const elements = document.querySelectorAll(".MuiAccordion-root ");
    elements.forEach(element => {
      if (isBodyClassAdded) {
        element.classList.add("downloadacc");
      } else {
        element.classList.remove("downloadacc");
      }
    });
  }, [isBodyClassAdded]);

  useEffect(() => {
    const elements = document.querySelectorAll(".MuiCardHeader-action");
    elements.forEach(element => {
      if (isBodyClassAdded) {
        element.classList.add('teamDownload');
      } else {
        element.classList.remove('teamDownload');
      }
    });
  }, [isBodyClassAdded]);
  //user first visit

  /**
   * Function to get leadself dashboard lock or not
   *
   * @async
   * @returns {unknown}
   */
  // const getLeadselfLockStatus = async () => {
  //   try {
  //     let resonse = await surveyAPIService.checkSurveyStatus(accessToken);
  //     setWiredUpStatus(resonse.data.wiredupIsLocked);
  //     setWorkWithStatus(resonse.data.workwithIsLocked);
  //     // return resonse.data.wiredupIsLocked
  //   } catch (error) {
  //     return error
  //   }
  // }
  // useEffect(() => {
  //   getLeadselfLockStatus();
  //   // eslint-disable-next-line
  // }, [])
  const getLeadselfLockStatus = async () => {
    try {
      let resonse = await surveyAPIService.checkSurveyStatus(accessToken);
      return resonse.data.wiredupIsLocked
    } catch (error) {
      return error
    }
  }
  const getWorkwithStatus = async () => {
    try {
      let resonse = await surveyAPIService.checkSurveyStatus(accessToken);
      return resonse.data.workwithIsLocked
    } catch (error) {
      return error
    }
  }
  // const getWorkwithStatus = async () => {
  //   try {
  //     let resonse = await surveyAPIService.checkSurveyStatus(accessToken);
  //     return resonse.data.workwithIsLocked
  //   } catch (error) {
  //     return error
  //   }
  // }

  /**
   * Function to check my leadership dashboard locked or not
   *
   * @async
   * @returns {unknown}
   */
  const getMyLeadershipStatus = async () => {
    try {
      let response = await myLeadershipApi.getMyLeadershStatus(accessToken)
      return response.resData.resData.isLocked;
    } catch (error) {
      return error;
    }
  }
  const getMyLeadershipDashboardData = async (inviteId) => {
    try {
      setIsLoadingml(true);

      const response = await myLeadershipApi.getMyLeadershipDashboard(accessToken, inviteId);

      if (response.resData.length > 0) {

        // Set your data here
        setIsLoadingml(false);
        return true; // Data is loaded
      } else {
        setIsLoadingml(false);
        return false; // No data
      }
    } catch (error) {
      console.error("Error fetching leadership dashboard data:", error);
      setIsLoadingml(false);
      return false;
    }
  };

  /**
   * Function to check executive health dashbaord locked or not
   *
   * @async
   * @returns {unknown}
   */
  const getExecutiveHealthStatus = async () => {
    try {
      let response = await executiveHealthResultsApi.getHealthSurveyResults(accessToken);
      return response.data.statusData.lockStatus
    } catch (error) {
      return error
    }
  }

  const getExecutiveHealthDataStatus = async () => {
    try {
      const response = await executiveHealthResultsApi.getHealthSurveyResults(accessToken);
      if (response && response.data) {
        return true; // Data is loaded successfully
      }
      return false; // No data loaded
    } catch (error) {
      console.error("Error loading survey results:", error);
      return false; // Indicate failure to load data
    }
  };
  return (
    <Box
      component="header"
      id="topNavWidth"
      sx={{
        '&.profileswitch': { display: 'none' },
        backdropFilter: "blur(6px)",
        paddingRight: '16px',
        backgroundColor: '#f9f9f9',
        position: "sticky",
        transition: 'width 0.3s ease, left 0.3s ease',
        left: {
          lg: isMinimized ? SIDE_NAV_MINIMIZED_WIDTH : SIDE_NAV_WIDTH,

        },
        '&.no-permision #downloadButton': { display: 'none' },
        '&.no-permision #downloadButton1': { display: 'none' },
        '&.no-htb #downloadButton1': { display: 'none' },
        top: 0,
        width: {

          lg: `calc(100% - ${isMinimized ? SIDE_NAV_MINIMIZED_WIDTH : SIDE_NAV_WIDTH}px)`,
        },
        zIndex: (theme) => theme.zIndex.appBar,
      }}
      {...other}
    >
      <Stack
        alignItems="flex-end"
        direction="row"
        justifyContent="space-between"
        spacing={2}
        sx={{
          minHeight: TOP_NAV_HEIGHT,
          px: 2,
          marginBottom: 1.5
        }}
      >
        <Stack alignItems="center"
          direction="row"
          spacing={1}
          sx={{ flexGrow: '2', }}
        >
          {!lgUp && (
            <IconButton onClick={onMobileNavOpen}>
              <SvgIcon>
                <Menu01Icon />
              </SvgIcon>
            </IconButton>
          )}
          <Typography pl={{ lg: '25px', md: '25px', sm: "10px", xs: '10px' }}
            ml={{ lg: '0px', md: '0px', sm: "0px", xs: '0px' }}
            sx={typography.profileHeaing}

          >
            {/* {toptitle} */}
            {title}

          </Typography>
          <SearchButton />
        </Stack>
        <Stack alignItems="center"
          direction="row"
          justifyContent="space-around"

          spacing={0}>
          {/* Conditional rendering of Download button */}
          {!isLeadSelfSettingsPage && (
            <Stack alignItems="center"
              direction="row"
              justifyContent="space-around"
              spacing={0}>
              <SldButtons.WhiteButtonSIcon
                sx={{ flexWrap: 'wrap', display: 'flex' }}
                id="downloadButton"
                variant="outlined"
                loading={isLoading}
                onClick={openPopup}
                startIcon={<DownloadPageIcon />}
                className='joyride-target-4'
              >
                <span>{isMobile ? null : 'Download Page'}</span>
              </SldButtons.WhiteButtonSIcon>
            </Stack>
          )}
        </Stack>
        <Stack alignItems="center"
          direction="row"
          justifyContent="space-around"
          spacing={0}>
          <IconButton id="downloadButton1"
            onClick={handleClickStart}>
            <HelpOutlineIcon className='joyride-target-5' />
          </IconButton>


          <>

            <Joyride
              callback={handleJoyrideCallback}
              continuous
              run={run}
              // stepIndex={currentStep}
              scrollToFirstStep={false}
              disableOverlayClose={true}
              disableCloseOnEsc={true}
              showProgress
              showSkipButton
              scrollOffset={currentScrollOffset}
              steps={steps}
              disableScrolling={disableScroll}
              tooltipComponent={CustomTooltip}
              disableScrollParentFix={true}


              styles={{
                options: {
                  zIndex: 10000,
                },
              }}
            />
            {openAddMembersForm}
          </>


          <IconButton id="downloadButton2"
            onClick={navigateToExplore}>
            <LanguageIcon />
          </IconButton>
          <AccountButton />
        </Stack>
      </Stack>
      <Divider sx={{ mx: 6 }} />
      <DownloadPagePopUp open={isPopupOpen}
        onClose={closePopup}
        surveyId={6}
        fileName={title}
        isGp={currentPaths === "/goalsAndPulses" ? true : false} />
    </Box>
  );
};

TopNav.propTypes = {
  onMobileNavOpen: PropTypes.func,
};
