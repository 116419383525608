/**
 * @Author: Anandp
 * @Date:   2024-05-01 20:06:15
 * @Last Modified by:   Anandp
 * @Last Modified time: 2024-05-01 20:08:17
 */
import React from "react";
import { Box, Stack, Container, Typography,  } from '@mui/material';
import { ReactComponent as SmartLeader } from "src/icons/untitled-ui/navSmartLeader.svg";
import * as SldSurveyCard from "src/theme/base/SldSurveyCard";
import { createTypography } from "src/theme/base/customTypography";
import LinkOffRoundedIcon from '@mui/icons-material/LinkOffRounded';

function LinkExpired(props) {
  const typography = createTypography();
  return (

    <Box
    component="main"
    sx={{
      alignItems: 'flex-start',
      display: 'flex',
      flexGrow: 1,
      height:'100vh'
    
    }}
  >
    <Container maxWidth="lg">
    <Stack 
          alignItems="center" 
          direction="column" 
          justifyContent="center"
          spacing={5} 
          sx={{ p: 0 }}>
            <SldSurveyCard.SmartLeaderBox 
            sx={{alignSelf:'flex-start',paddingTop:'25px'}}
           >
              <SmartLeader width={220}/>
            </SldSurveyCard.SmartLeaderBox>
            <Box 
            sx={{'& svg':{width:'80px', height:'80px',fill:'#535B66'},display:'flex', justifyContent:'center', alignItems:'center',width:'120px', height:'120px',borderRadius:'50%',border:'2px solid #535B66',textAlign:'center'}}> <LinkOffRoundedIcon/></Box>
            
          </Stack>
          <Typography sx={{...typography.lockScreen,textAlign:'center',marginTop:'15px'}}>
        Oops! It looks like the link has expired or the survey has already been completed.</Typography>
        <p style={{textAlign:'center'}}>If you need further assistance, please reach out to our support team.</p>
            </Container>
      </Box>
      
  );
}
 
export default LinkExpired;