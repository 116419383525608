/**
 * @Author: Anandp
 * @Date:   2024-07-25 06:41:02
 * @Last Modified by:   Anandp
 * @Last Modified time: 2024-12-25 18:33:10
 */
import { lazy, Suspense } from "react";
import { Outlet } from "react-router-dom";
import { AuthGuard } from "src/guards/auth-guard";
import { LeadSelfLayout } from "src/layouts/leadselfDashboard";
import { LeadSelfSettings } from "src/pages/LeadSelfSettings/LeadSelfSettings"
import { DownloadProvider } from "src/contexts/downloadContext";
const LeadSelfpage = lazy(() =>
  import("src/pages/leadSelfDashboard/leadSelfPage")
);
const Mojo = lazy(() => import("src/pages/myMojoDashboard/myMojoPage"));
const MyExecutiveHealth = lazy(() =>
  import("src/pages/myExecutiveHealth/myExecutiveHealthPage")
);
const GoalsAndPulses = lazy(() =>
  import("src/pages/goalsAndPulses/goalsAndPulsesPage")
);
const MyGoals = lazy(() =>
  import("src/pages/goalsAndPulses/overviews/myGoals/myGoalsPage")
);
const MyExecutiveHealthKPI = lazy(() =>
  import("src/pages/myExecutiveHealth/myExecutiveHealthKPI")
);
const MyLeadership = lazy(() =>
  import("src/pages/myLeadership/myLeadershipPage")
);
const ConversationWithBoss = lazy(() =>
  import("src/pages/conversationWithBoss/conversationWithBossPage")
);
const MyPulseTrends = lazy(() =>
  import("src/pages/mypulseTrends/myPulseTrendsPage")
);
const DepartmentCoachesCorner = lazy(() =>
  import("src/pages/coaches-corner/departmentCoachesCorner")
);
const TeamsCoachesCorner = lazy(() =>
  import("src/pages/coaches-corner/teamsCoachesCorner")
);
const MembersCoachesCorner = lazy(() =>
  import("src/pages/coaches-corner/membersCoachesCorner")
);
const PrepSheetsCoachesCorner = lazy(() =>
  import("src/pages/coaches-corner/prepSheetsCoachesCorner")
);
const SyndicateCoachesCorner = lazy(() =>
  import("src/pages/coaches-corner/syndicateCoachesCorner")
);


const ProgrammeMembers = lazy(() =>
  import("src/pages/coaches-corner/programmeMembers")
);


export const leadSelfRoutes = [
  {
    path: "",
    element: (
      <AuthGuard>
        <DownloadProvider>
          <LeadSelfLayout>
            <Suspense>
              <Outlet />
            </Suspense>
          </LeadSelfLayout>
        </DownloadProvider>
      </AuthGuard>
    ),
    children: [
      {
        path: "leadSelf",
        index: true,
        element: <LeadSelfpage />,
      },
      {
        path: "leadSelfSettings",  //working
        index: true,
        element: <LeadSelfSettings />,
      },
      {
        path: "mymojo",
        index: true,
        element: <Mojo />,
      },
      {
        path: "myExecutiveHealth",
        index: true,
        element: <MyExecutiveHealth />,
      },
      {
        path: "goalsAndPulses",
        index: true,
        element: <GoalsAndPulses />,
      },
      {
        path: "myGoals/:id",
        index: true,
        element: <MyGoals />,
      },
      {
        path: "executiveHealthKPI",
        index: true,
        element: <MyExecutiveHealthKPI />,
      },
      {
        path: "myLeadership",
        index: true,
        element: <MyLeadership />,
      },
      {
        path: "conversationWithBoss",
        index: true,
        element: <ConversationWithBoss />,
      },
      {
        path: "myPulseTrends",
        index: true,
        element: <MyPulseTrends />,
      },
      {
        path: "coachescorner",
        index: true,
        element: <DepartmentCoachesCorner />,
      },
      {
        path: "teams/:tenantId/:customerid",
        index: true,
        element: <TeamsCoachesCorner />,
      },
      {
        path: "members/:tenantId/:categoryId/:customerid/:teamName",
        index: true,
        element: <MembersCoachesCorner />,
      },
      {
        path: "prepSheets/:firstName/:lastName/:tenantId/:userId/:categoryId/:customerid/:teamName",
        index: true,
        element: <PrepSheetsCoachesCorner />,
      },

      {
        path: "syndicate/:programmeId",
        index: true,
        element: <SyndicateCoachesCorner />,
      },

      {
        path: "programmeMember/:category_id/:programmeId/:teamName/:tenantId",
        index: true,
        element: <ProgrammeMembers />,
      },
    ],
  },
];
