/**
 * @Author: Anandp
 * @Date:   2024-07-25 06:41:02
 * @Last Modified by:   Anandp
 * @Last Modified time: 2024-12-23 21:46:23
 */
import axios from "axios";
import { Url } from "src/config";

const teamDashboardResultsApi = {
  getMyMojoMeterResult: async (accessToken, id, teamId, date) => {
    try {
        const params = {
            id: id,
            teamId: teamId,
        };

        if (date) {
            params.date = date;
        }

        const response = await axios.get(
            `${Url}/customer/getMyMojoTeamMeterResult`,
            {
                headers: {
                    "access-token": accessToken,
                },
                params: params,
            }
        );
        return response.data;
    } catch (error) {
        return error;
    }
},
  getTeamDiversityResults: async (accessToken, id, teamId) => {
    try {
      const response = await axios.get(`${Url}/customer/getTeamDiversityResults`,{
        headers: {
          "access-token": accessToken,
        },
        params: {
          id: id,
          teamId: teamId,
        },
      });
      return response.data;
    } catch (error) {
      return error
    }
  },
  getClickWithOrgResults: async (accessToken, id) => {
    try {
      const response = await axios.get(`${Url}/customer/getClickWithOrgResults`, {
        headers: {
          "access-token": accessToken,
        },
        params: {
          id: id,
        },
      });
      return response.data;
    } catch (error) {
      console.log(error.message);
    }
  },
  getUsersTeams: async (accessToken, id) => {
    try {
      const response = await axios.get(`${Url}/customer/getUsersTeams`,{
        headers: {
          "access-token": accessToken,
        },
        params: {
          id: id
        },
      });
      return response.data;
    } catch (error) {
      return error
    }
  },
  getTeamCompatabilityResults: async (accessToken, id, teamId) => {
    try {
      const response = await axios.get(`${Url}/customer/getTeamCompatabilityResults`,{
        headers: {
          "access-token": accessToken,
        },
        params: {
          id: id,
          teamId: teamId,
        },
      });
      return response.data;
    } catch (error) {
      return error
    }
  },
  checkTeamLeader: async (teamId, id, accessToken) => {
    try {
      const response = await axios.get(`${Url}/customer/CheckingTeamLeader`, {
        headers: {
          "access-token": accessToken
        },
        params: {
          id: id,
          teamId: teamId,
        }
      });
      return response.data;
    } catch (error) {
      return error
    }
  }

}

export default teamDashboardResultsApi;