/**
 * @Author: Anandp
 * @Date:   2024-04-17 18:40:50
 * @Last Modified by:   Anandp
 * @Last Modified time: 2024-12-23 17:05:18
 */
import React, { useEffect } from "react";
import { Backdrop, CircularProgress} from "@mui/material";
import { ReactComponent as SmartLeaderLogo } from "../../icons/untitled-ui/smartLeaderWhite.svg";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
// import SurveyTile from "./surveys/surveyCards";
import { RouterLink } from "src/components/router-link";
// import surveyAPIService from "src/services/surveyApi";
// import surveyAPIService from "src/services/surveyApi";
import { useUser } from "src/hooks/use-user";
import { useState } from "react";
import { createTypography } from "../../theme/base/customTypography";
import loginApiService from "src/services/loginAPI";
import * as SldButtons from "src/theme/base/SldButtons";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import * as SldSurveyCard from "src/theme/base/SldSurveyCard";
import TeamEffectivenessSurveyTile from "./components/surveyCards";
import { useLocation } from "react-router";
import LinkExpired from "../wiredUpSurveys/surveys/linkExpire";



function TeamEffectivenessStartPageLayout(props) {
    const { children } = props;
    // const id = 7;
    // eslint-disable-next-line
    const token = "tokenuser";
    // const sectionPage = 1;
  
    // const accesssToken = sessionStorage.getItem("accessToken");
    const user = useUser();
    const queryParameters = new URLSearchParams(window.location.search);
    //   const surveyId = queryParameters.get("surveyId");
    // let surveyId = 6;
    const [inviteId, setInviteId] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [emailToken, setEmailToken] = useState(null);
    const [emailInviteId, setEmailInviteId] = useState(null);
    const [tenantId, setTenantId] = useState(null);
    // const [teamId,setTeamId] = useState(null);
    const [queueId, setQueueId] = useState();
    const [surveyId, setSurveyId] = useState(queryParameters.get("surveyId"));
    // const [invitedUserName, setInvitedUserName] = useState();
   const [teamId,setTeamId] = useState(null);
   const [valid, setValid] = useState(0);
    // const [invitedUserId , setInvitedUserId] = useState(
    //   queryParameters.get("invitedUserId")
    // );
    // let surveyLinkParameters = "";
    // let pulse = 1;
    const location = useLocation();
  
    /**
     * Function to get invite id , If no invite id is present new invite id will be created
     * @date 11/5/2023 - 12:07:16 AM
     *
     * @async
     * */
    // const getInviteId = async () => {
    //   try {
    //     const inviteIdGetData = {
    //       survey_id: surveyId,
    //       inviteId: inviteId,
    //       timeStamp: "",
    //       teamId: "",
    //     };
    //     //api call to check invite id
    //     const response = await surveyAPIService.checkInviteId(
    //       inviteIdGetData,
    //       accesssToken
    //     );
    //     setInviteId(response.data.inviteId);
    //   } catch (error) {}
    // };
    const validateToken = async () => {
      try {
        const response = await loginApiService.decodeToken(emailToken);
        if (response?.userId) {
          const stringifiedUser = JSON.stringify(response);
          sessionStorage.setItem("userDetails", stringifiedUser);
          sessionStorage.setItem("accessToken", emailToken);
          setInviteId(emailInviteId);
        }
      } catch (error) {}
    };
    const validateLink = async () => {
      try {
        const response = await loginApiService.validateTeamLink(emailToken, queueId,tenantId,inviteId,user.id);
        setValid(response[0].is_completed);
        setIsLoading(false)
      } catch (error) {
  
      }
    }
    useEffect(()=>{
      if(inviteId && emailInviteId && emailToken){
        validateLink();
        setIsLoading(false)
        setValid(0)
      }
      // eslint-disable-next-line
    },[inviteId,emailInviteId, emailToken])
  
    useEffect(() => {
      if (emailInviteId && emailToken) {
        validateToken();
        
      } 
      // else {
      //   getInviteId();
      // }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [emailInviteId, emailToken]);
    useEffect(() => {
      if (location.search.substring(1) && !queryParameters.get("surveyId")) {
        const base64String = location.search.substring(1);
  
        // Decode the base64 string to get the original string
        const decodedString = atob(base64String);
  
        // Split the decoded string to get individual parameters
        const paramsArray = decodedString.split("&");
  
        // Create an object to store the parameters
        const params = {};
  
        // Populate the params object
        paramsArray.forEach((param) => {
          const equalSignIndex = param.indexOf("=");
          const key = param.slice(0, equalSignIndex);
          const value = param.slice(equalSignIndex + 1);
          params[key] = value;
        });
        // Set your state variables accordingly
        setEmailInviteId(params.inviteId);
        setEmailToken(params.token);
        setSurveyId(params.surveyId);
        setQueueId(params.queueId);
        setTeamId(params.teamId);
        setTenantId(params.tenantId)
      } else {
        // setInvitedUserName(user.firstName);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.search]);
    // useEffect(() => {
    //   if (emailInviteId && emailToken) {
    //     validateToken();
    //   } 
    //   // else if (accesssToken && emailToken === null && emailInviteId === null) {
    //   //   getInviteId();
    //   // }
    //   // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [emailInviteId, emailToken]);


    if (isLoading) {
      return (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      );
    }
    const typography = createTypography();
    if (valid === 1) {
      return <LinkExpired />;
    }
  
  return (
    <SldSurveyCard.SurveyGrid
      container
      spacing={0}
      
    >
      <SldSurveyCard.SurveyGridItem
        item
        lg={12}
        sm={12}
      >
        <SldSurveyCard.SurveyLogo>
          <SmartLeaderLogo width='250' />
        </SldSurveyCard.SurveyLogo>
        <Stack
          justifyContent="space-between"
          alignItems="center"
          spacing={6}
          
        >
          <Typography sx={typography.qNheading}>
            Team Effectiveness Questionnaire
          </Typography>
          {/* <Typography sx={typography.qNTitle}>
            Only <span style={{ fontWeight: 600 }}>15 minutes </span>to get a
            description of who you are and why you do things the way you do.
          </Typography> */}
          {/* <Grid> */}
          <SldButtons.SurveyClock 
            variant="contained">
          <AccessTimeIcon />
            <Typography variant="subtitle1"> Duration <span>15 mins</span></Typography>
          </SldButtons.SurveyClock>
          {/* </Grid> */}


        </Stack>
      </SldSurveyCard.SurveyGridItem>
      <SldSurveyCard.SurveyStack
    spacing={3}
    
    sx={{
      marginTop:{lg :'-140px', sm :'5px', xs :'3px'},
      padding:{lg:'0 60px', md:'0 60px', sm:'0 0', xs:'0'},
    }}
  >
      <TeamEffectivenessSurveyTile />

      <SldSurveyCard.SurveyRules 
      lg={12} 
     >
        <SldSurveyCard.SurveyContainer>{children}</SldSurveyCard.SurveyContainer>
        <SldButtons.WhiteButtonCap endIcon={<ArrowForwardIcon />}
            variant="contained"
            component={RouterLink}
            to={`/surveyQuestions?id=${surveyId}&inviteId=${inviteId}&teamId=${teamId}&queueId=${queueId}&tenantId=${tenantId}`}
          >
            <Typography
              sx={{ ...typography.startButton}}
            >
              Start
            </Typography>
            
          </SldButtons.WhiteButtonCap>
      </SldSurveyCard.SurveyRules>
</SldSurveyCard.SurveyStack>
      
    </SldSurveyCard.SurveyGrid>
  );
}

export default TeamEffectivenessStartPageLayout;
