/**
 * @Author: Anandp
 * @Date:   2024-12-25 18:02:02
 * @Last Modified by:   Anandp
 * @Last Modified time: 2024-12-25 18:21:35
 */
import React, { createContext, useContext, useState } from 'react';

const downloadContext = createContext();

export const DownloadProvider = ({ children }) => {
  const [downloadContextState, setDownloadContextState] = useState(false);

  const setStateContext = (state) => {
    setDownloadContextState(state);
  };

  return (
    <downloadContext.Provider value={{ downloadContextState, setStateContext }}>
      {children}
    </downloadContext.Provider>
  );
};

export const useDownload = () => {
  return useContext(downloadContext);
};