/**
 * @Author: Anandp
 * @Date:   2024-09-09 17:17:39
 * @Last Modified by:   Anandp
 * @Last Modified time: 2024-12-23 21:37:28
 */
import * as React from "react";
import { useEffect, useState, useContext } from "react";
import axios from "axios";
import { useTheme } from "@mui/material/styles";
// import Box from "@mui/material/Box";
// import Table from "@mui/material/Table";
// import TableBody from "@mui/material/TableBody";
// import TableCell from "@mui/material/TableCell";
// import TableContainer from "@mui/material/TableContainer";
// import TableFooter from "@mui/material/TableFooter";
// import TablePagination from "@mui/material/TablePagination";
// import TableRow from "@mui/material/TableRow";
// import Paper from "@mui/material/Paper";
// import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { AddMemberPopUp } from "src/pages/LeadSelfSettings/popups/userAdd";
import { RequestSurveysModal } from "src/pages/LeadSelfSettings/popups/requestSurvey";
import { JoyrideContext } from 'src/contexts/JoyrideContext';

import {
  Autocomplete,
  InputAdornment,
  TableHead,
  TextField,
  Typography,
  CircularProgress,
  FormControl,
  Checkbox,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TablePagination,
  TableRow,
  Box,
  FormControlLabel,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import * as SldButtons from "src/theme/base/SldButtons";
import { RemoveMemberPopUp } from "./popups/removeMember";
import { Url } from "src/config";

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

export const LeadSelfSettings = () => {
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [teamMembers, setTeamMembers] = useState([]);
  const [page, setPage] = useState(0);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  // const [openRequestPopup, setOpenRequestPopup] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  // eslint-disable-next-line
  const { openAddMembersForm, setOpenAddMembersForm, addSteps, currentStep,continueJoyride,openRequestForm,setOpenRequestForm } = useContext(JoyrideContext);
  const [loadingTeams, setLoadingTeams] = useState(true);
  const [loadingMembers, setLoadingMembers] = useState(false);


  const [teams, setTeams] = useState([]); // Define and initialize the teams state variable

  const accessToken = sessionStorage.getItem("accessToken");

  // Fetch teams when component mounts

  /**
 * Fetches a list of teams associated with the customer from the backend API.
 * 
 * @function fetchTeams
 * @async
 * @description Makes an asynchronous GET request to retrieve team data from the server. 
 *              If successful, it updates the `teams` state with the fetched data. 
 *              Handles any errors encountered during the API call and logs appropriate messages.
 * 
 * @throws Will log an error message if the API call fails or returns an unsuccessful response.
 * 
 * @example
 * Automatically invoked by the `useEffect` hook to fetch team data.
 * Updates the state with team details on success or logs an error on failure.
 */

  useEffect(() => {
    const fetchTeams = async () => {
      try {
        const response = await axios.get(`${Url}/customer/teams`, {
          headers: {
            "access-token": accessToken,
          },
        });
        if (response.data.success) {
          setTeams(response.data.details);
        } else {
          console.error("Failed to fetch teams:", response.data.message);
        }
      } catch (error) {
        console.error("Error fetching teams:", error);
      } finally {
        setLoadingTeams(false);
      }
    };

    fetchTeams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Fetch team members based on selected team

  /**
 * Fetches and processes team members for the selected team.
 * 
 * @function fetchTeamMembers
 * @description Triggered by the `useEffect` hook whenever `selectedTeam` changes. 
 *              Fetches members of the selected team from the backend API and sorts them alphabetically 
 *              by first name and last name. If no team is selected, it clears the `teamMembers` state and deselects all rows.
 * 
 * @param {Object} selectedTeam - The currently selected team object. If null or undefined, no fetch is performed.
 * @param {string} selectedTeam.category_id - The unique identifier for the selected team's category.
 * 
 * @throws Will log an error message if the API call to fetch team members fails.
 * 
 * @example
 * Automatically invoked by the `useEffect` hook whenever the selected team changes.
 * Updates the `teamMembers` state with sorted team members or clears it when no team is selected.
 */


  useEffect(() => {
    if (selectedTeam) {
      setLoadingMembers(true);
      
      axios
        .get(`${Url}/customer/teamUsers/${selectedTeam.category_id}`, {
          headers: {
            "access-token": accessToken,
          },
        })
        .then((response) => {
          // Sort the team members alphabetically by first name and last name
          const sortedMembers = response.data.details.sort((a, b) => {
            const nameA = `${a.firstName} ${a.lastName}`.toLowerCase();
            const nameB = `${b.firstName} ${b.lastName}`.toLowerCase();
            if (nameA < nameB) return -1;
            if (nameA > nameB) return 1;
            return 0;
          });
          setTeamMembers(sortedMembers);
        })
        .catch((error) => {
          console.error("Error fetching team members:", error.message); // Log error
        })
        .finally(() => {
          setLoadingMembers(false);
        });
    } else {
      setTeamMembers([]);
      setSelectedRows([]); // Deselect all team members when no team is selected
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTeam]);

  // Team change function

/**
 * Handles the selection of a team and updates the application's state.
 * 
 * @function handleTeamChange
 * @description This function is triggered when a user selects a team. 
 *              It updates the `selectedTeam` state and dynamically adds 
 *              Joyride steps to the application's guided tour if steps are defined.
 * 
 * @param {Object} event - The event object triggered by the change action (e.g., from a dropdown or autocomplete).
 * @param {Object|null} value - The selected team object or `null` if no team is selected.
 * 
 * @example
 * When a team is selected, the `selectedTeam` state is updated, 
 * and Joyride steps are added for guiding the user.
 * handleTeamChange(event, selectedTeam);
 */

  const handleTeamChange = (event, value) => {
    setSelectedTeam(value);
    const steps = [                  {
      content: 'Select the necessary team members and proceed to complete any of these functions',
      placement: 'left-start',
      target: '.joyride-target-lsn2',
      spotlightClicks: true,
      id:'jrls-3n',
      width:'200',
      title: 'Last'
    },

    ]
    // Add Joyride steps if available
    if (steps.length > 0) {
      addSteps(steps);
    }
  };

  
const handleRequestSurveys = () => {
  setOpenRequestForm(true);
};


  const handleCloseRequestPopup = () => {
    setOpenRequestForm(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to the first page when rows per page change
  };

  const handleSelectAll = (event) => {
    const isChecked = event.target.checked;
    setSelectAll(isChecked);
    if (isChecked) {
      setSelectedRows(
        filteredMembers.map((member) => ({
          id: member.id,
          userName: member.userName,
        }))
      );
    } else {
      setSelectedRows([]);
    }
  };

  const handleRowSelect = (event, userId, userName) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setSelectedRows((prevSelectedRows) => [
        ...prevSelectedRows,
        { id: userId, userName: userName },
      ]);
    } else {
      setSelectedRows((prevSelectedRows) =>
        prevSelectedRows.filter((row) => row.id !== userId)
      );
    }
    const steps = [{
      content: 'The details of the selected team will be displayed here',
      placement: 'bottom-start',
            target: '.joyride-target-ls2',
      spotlightClicks: false,
      id:'jrls-3',
      width:'200'
    },

    {
      content: 'Select the necessary team members and proceed to complete any of these functions',
      placement: 'bottom-start',
      target: '.joyride-target-lsn2',
      spotlightClicks: true,
      id:'jrls-3n',
      width:'200'
    },

   
    ]
    // Add Joyride steps if available
    if (steps.length > 0) {
      addSteps(steps);
    }
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleRemoveMembers = () => {
    setOpenDeletePopup(true);
  };

  const handleCloseDeletePopup = () => {
    setOpenDeletePopup(false);
    if (deleteSuccess) {
      const updatedMembers = teamMembers.filter(
        (member) => !selectedRows.includes(member.user_id)
      );
      setTeamMembers(updatedMembers);
      setSelectedRows([]);
      setDeleteSuccess(false);
    }
  };

  const handleAddTeamMembers = () => {
    setOpenAddMembersForm(true);
   
  };

  const handleCloseAddMembersForm = () => {
    setOpenAddMembersForm(false);
  };

  // Filtering members

  /**
 * Filters and sorts team members based on a search query and calculates the number of empty rows for pagination.
 * 
 * @constant {Array} filteredMembers
 * @description Filters the `teamMembers` array to include only those whose first name matches the `searchQuery` (case-insensitive).
 *              Additionally, sorts the filtered members alphabetically by first name and last name.
 *              If `teamMembers` is null or undefined, an empty array is returned.
 * 
 * @constant {number} emptyRows
 * @description Calculates the number of empty rows needed for maintaining pagination alignment 
 *              when the number of filtered members does not fill a complete page.
 * 
 * @example
 * Assuming `teamMembers` contains an array of members and `searchQuery` is "john":
 * const filteredMembers = [
 *   { firstName: "John", lastName: "Doe" },
 *   { firstName: "Johnny", lastName: "Appleseed" }
 * ];
 * 
 * If there are 3 rows per page and `filteredMembers` has 2 members:
 * const emptyRows = 1; // 1 empty row to fill the last page.
 */

  const filteredMembers = teamMembers
    ? teamMembers
      .filter((member) =>
        member.firstName.toLowerCase().includes(searchQuery.toLowerCase())
      )
      .sort((a, b) => {
        const nameA = `${a.firstName} ${a.lastName}`.toLowerCase();
        const nameB = `${b.firstName} ${b.lastName}`.toLowerCase();
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      })
    : [];
  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - filteredMembers.length)
      : 0;


  return (
    <>
      <Box sx={{ width: "90%", margin: "auto" }}
      
      >
        <>
        <div className='joyride-target-ls678'>
        <div className='joyride-target-ls8'>
        <div className='joyride-target-ls1'>
          <FormControl fullWidth
           className="joyride-target-exls5"
            sx={{ marginBottom: 2 }}>
            {loadingTeams ? (
              <Box display="flex"
                justifyContent="center">
                <CircularProgress />
              </Box>
            ) : (
              <Autocomplete
                options={teams}

                getOptionLabel={(option) => option.name}
                value={selectedTeam}
                onChange={handleTeamChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Team"
                    margin="normal"
                    fullWidth
                  />
                )}
              />
            )}
          </FormControl>

          <TextField
            label="Search"
            value={searchQuery}
            onChange={handleSearchChange}
            style={{ marginBottom: 20, width: "30%" }}
            margin="normal"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </div>
        </div></div>
        
        </>
        <>
          <div 
                className='joyride-target-lsn2'
          
          >
            <TableContainer component={Paper}
className='joyride-target-ls2'>
              {loadingMembers ? (
                <Box display="flex"
                  justifyContent="center"
                  my={3}>
                  <CircularProgress />
                </Box>
              ) : (
                <Table sx={{ minWidth: 500 }}

                  aria-label="custom pagination table">
                  <TableHead>
                    <TableRow>
                      <TableCell>NAME</TableCell>
                      <TableCell>MEMBER ROLE</TableCell>
                      {/* <TableCell>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      ACTIONS
                      <Checkbox
                        checked={selectAll}
                        onChange={handleSelectAll}
                        inputProps={{ "aria-label": "select all members" }}
                      />
                    </div>
                  </TableCell> */}
                      {/* <TableCell>ACTIONS</TableCell> */}
                      <TableCell className='joyride-target-lsn2'>  Select All
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={selectAll}
                              onChange={handleSelectAll}
                              inputProps={{ "aria-label": "select all members" }}
                            />
                          }
                          // label={
                          //   <Typography
                          //     variant="body1"
                          //     style={{ fontWeight: "bold" }}
                          //   >MTP Joyride Team
                          //     Select All
                          //   </Typography>
                          // }
                          labelPlacement="start"
                        />
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(rowsPerPage > 0
                      ? filteredMembers.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      : filteredMembers
                    ).map((member, index) => (
                      <TableRow key={index}>
                        <TableCell >
                          {`${member.firstName} ${member.lastName}`}
                        </TableCell>
                        <TableCell >
                          {member.admin_role_name}
                        </TableCell>
                        <TableCell >
                          <Checkbox
                            checked={selectedRows.some(
                              (row) => row.id === member.id
                            )}
                            onChange={(event) =>
                              handleRowSelect(event, member.id, member.userName)
                            }
                            inputProps={{ "aria-labelledby": `checkbox-${index}` }}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[
                          5,
                          10,
                          25,
                          { label: "All", value: -1 },
                        ]}
                        colSpan={3}
                        count={filteredMembers.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        slotProps={{
                          select: {
                            inputProps: {
                              "aria-label": "rows per page",
                            },
                            native: true,
                          },
                        }}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              )}
            </TableContainer>

            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                marginTop: "20px",
                marginBottom: "30px",
              }}
              className='lsbuttons'
            >
              <SldButtons.PurpleButton
                variant="contained"
                color="primary"
                className='joyride-target-ls67'
                onClick={handleRequestSurveys}
                style={{ textTransform: "none" }}
                disabled={!selectedTeam || selectedRows.length === 0}
              >
                Request Surveys
              </SldButtons.PurpleButton>
              <SldButtons.PurpleButton
                variant="contained"
                className='joyride-target-lsn22'
                color="primary"
                onClick={handleAddTeamMembers}
                style={{ marginLeft: "10px", textTransform: "none" }}
                disabled={!selectedTeam}
                
              >
                Add new team member
              </SldButtons.PurpleButton>
              <SldButtons.PurpleButton
                variant="contained"
                color="primary"
                onClick={handleRemoveMembers}
                style={{ marginLeft: "10px", textTransform: "none" }}
                disabled={selectedRows.length === 0}
              >
                Remove team member
              </SldButtons.PurpleButton>
            </div>
            <div style={{ marginTop: "auto" }}>
              <Typography variant="caption"
                align="center"
                style={{ fontSize: "15px" }}>
                Note: If you would like to create new teams, please contact the
                SmartLeader team at info@smartleaderdiagnostics.com or reach out
                directly to your account manager.
              </Typography>
            </div>
          </div>
        </>
        <RemoveMemberPopUp
          open={openDeletePopup}
          onClose={handleCloseDeletePopup}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          setSelectAll={setSelectAll}
          categoryId={selectedTeam?.category_id}
          category_name={selectedTeam?.name}
        />

        <AddMemberPopUp

          isOpen={openAddMembersForm}
          handleClose={handleCloseAddMembersForm}
          categotyId={selectedTeam?.category_id}
          category_name={selectedTeam?.name}
        />
        <RequestSurveysModal
          isOpen={openRequestForm}
          onClose={handleCloseRequestPopup}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          setSelectAll={setSelectAll}
          categoryId={selectedTeam?.category_id}
          category_name={selectedTeam?.name}
        // teamName={selectedTeam?.name} 
        />
      </Box>
    </>
  );
};
