/**
 * @Author: Anandp
 * @Date:   2024-04-17 18:40:50
 * @Last Modified by:   Anandp
 * @Last Modified time: 2024-12-23 22:04:55
 */
import axios from "axios";
import { Url } from "src/config";


const loginApiService = {
  changePassword: async (password, userEmail) => {
    try {
      // eslint-disable-next-line
      const response = await axios.post(`${Url}/module/changePassword`, { password, userEmail });
    } catch (error) {
      console.log(error.message);
    }
  },
  decodeToken: async (token) => {
    try {
      const tokenData = { accessToken: token };
      const response = await axios.post(`${Url}/auth/decodeToken`, tokenData)
      return response.data
    } catch (error) {

    }
  },
  validateLink: async (token, inviteId,surveyID) => {
    try {
      // const response = await axios.get(`${Url}/customer/validatelink?inviteId=${inviteId}`, tokenData)
      const response = await axios.get(
        `${Url}/customer/validatelink?inviteId=${inviteId}&surveyID=${surveyID}`,
        {
          headers: {
            "access-token": token,
          },

        }
      );
      return response.data.data
    } catch (error) {

    }
  },
  validateTeamLink: async (token, queueId, tenantId,inviteId,userId) => {
    try {
      let url = `${Url}/customer/validateteamlink?queueid=${queueId}`;
      
      // Include tenantId in the URL if it's present
      if (tenantId !== null && tenantId !== undefined && tenantId !=='') {
        url += `&tenant_id=${tenantId}`;
      }
  if(inviteId&&userId){
    url += `&inviteId=${inviteId}&userId=${userId}`
  }
      const response = await axios.get(
        url,
        {
          headers: {
            "access-token": token,
          },
        }
      );
      return response.data.data;
    } catch (error) {
      // Handle error
    }
  },
  listProfiles: async (accessToken) => {
    try {
      const response = await axios.get(`${Url}/customer/listMyProfiles`, {
        headers: {
          "access-token": accessToken,
        },
      });
      return response.data;
    } catch (error) {
      console.log(error.message);
    }
  },
  getToken: async (body, accessToken) => {
    try {
      const response = await axios.post(`${Url}/customer/switchprofile`, body, {
        headers: {
          "access-token": accessToken,
        },
      });
      return response.data;
    } catch (error) {
      console.log(error.message);
    }
  },
};
export default loginApiService;
