/**
 * @Author: Anandp
 * @Date:   2024-04-17 18:40:50
 * @Last Modified by:   Anandp
 * @Last Modified time: 2024-12-25 18:31:05
 */
import { Outlet } from "react-router-dom";
import { lazy, Suspense } from "react";
import { Layout as AuthModernLayout } from "src/layouts/auth/login";
import Error401Page from "src/pages/401";
import Error404Page from "src/pages/404";
import Error500Page from "src/pages/500";
import { dashboardRoutes } from "./dashboard";
import { wiredUpSurveyRoutes } from "./wiredUpSurvey";
import { surveyRoutes } from "./survey";
import { testingPage } from "./testingPage";
import { privacyPolicy } from "./privacyPolicy";
import { unSubscribe } from "./unSubscribe";
import { myMojoSurveyRoutes } from "./myMojoSurvey";
import { leadSelfRoutes } from "./leadSelf";
import { leadershipBrandSurveyRoutes } from "./leadershipBrandSurvey";
import { myEmotionalResilenceSurveyRoutes } from "./EmotionalResilienceSurvey";
import { ExecutiveHealthSurveyRoutes } from "./executiveHealthSurvey";
import { workWithSurveyRoutes } from "./workWithSurvey";
import { MTPRoutes } from "./MTPDashboard";
import { TeamDashboardRoutes } from "./teamDashboard";
import { myLeadershipSurveyRoutes } from "./myLeadershipsurvey";
import { exploreRoutes } from "./explorerPage";
import { MTPSUrveyRoutes } from "./MTPSurvey";
// import { CoachesCornerRoutes } from "./coachesCorner";
import { teamEffectivenessRoutes } from "./teamEffectiveness";
import { AddAndEditGoalRoute } from "./addAndEditGoal";
import { GoalsAndPulseFeedbackRoute } from "./goalsAndPulseFeedback";
import { _360UserInvite } from "./_063_UserInvite";
import { coachesCornerMyTeamPulseRoutes } from "./coachesCornerMyTeamPulse";
import { DownloadProvider } from "src/contexts/downloadContext";
const LoginModernPage = lazy(() => import("src/pages/auth-demo/login/login"));
const ChangePasswordPage = lazy(() =>
  import("src/pages/auth-demo/login/changePassword")
);
const ResetPage = lazy(() => import("src/sections/auth/jwt-reset"));
const OtpVerify = lazy(() => import("src/sections/auth/jwt-otp"));

const SharedDashboardPage = lazy(() =>
  import("src/pages/sharedDashboard/sharedDashboard")
);

const SharedMTPDashboardPage = lazy(() =>
  import("src/pages/sharedDashboard/sharedMTPDashboard")
);
const Download063Dashboard = lazy(() =>
  import("src/pages/myLeadership/overviews/popUpContents/downLoadPage")
);
const DownloadLBSDashboard = lazy(() =>
  import("src/pages/leadershipBrandSurvey/overviews/downLoadPage")
);
const DownloadClickwithDashboard = lazy(() =>
  import("src/pages/clickWith/overviews/popupContents/downLoadPage")
);
const DownloadMTPOrgNarrative = lazy(() =>
  import("src/pages/mtp/overviews/popupOverview/downLoadPage")
);
/********Link Redirection*************/
const ProcessLinkPage = lazy(() => import("src/pages/loader/LoaderPage"));
/********Link Redirection*************/

export const routes = [
  {
    element: (
      <AuthModernLayout>
        <Suspense>
          <Outlet />
        </Suspense>
      </AuthModernLayout>
    ),
    children: [
      {
        index: true,
        element: <LoginModernPage />,
      },
      {
        path: "/forgotPassword",
        element: <ResetPage />,
      },
      {
        path: "/verifyOtp",
        element: <OtpVerify />,
      },
      {
        path: "/changePassword",
        element: <ChangePasswordPage />,
      },
      {
        path: "/tdp/team-vibe-advanced",
        element: <ProcessLinkPage type="MTP" />,
      },
      {
        path: "/dashboard-personal063-colleague",
        element: <ProcessLinkPage type="063" />,
      },
      
    ],
  },
  //...authRoutes,
  // ...authDemoRoutes,
  ...surveyRoutes,
  ...wiredUpSurveyRoutes,
  ...dashboardRoutes,
  ...testingPage,
  ...privacyPolicy,
  ...unSubscribe,
  ...myMojoSurveyRoutes,
  ...leadershipBrandSurveyRoutes,
  ...leadSelfRoutes,
  ...myEmotionalResilenceSurveyRoutes,
  ...ExecutiveHealthSurveyRoutes,
  ...workWithSurveyRoutes,
  ...MTPRoutes,
  ...TeamDashboardRoutes,
  ...myLeadershipSurveyRoutes,
  ...exploreRoutes,
  ...MTPSUrveyRoutes,
  ...coachesCornerMyTeamPulseRoutes,
  // ...CoachesCornerRoutes,
  ...teamEffectivenessRoutes,
  ...AddAndEditGoalRoute,
  ...GoalsAndPulseFeedbackRoute,
  ..._360UserInvite,
  {
    path: "401",
    element: <Error401Page />,
  },
  {
    path: "404",
    element: <Error404Page />,
  },
  {
    path: "500",
    element: <Error500Page />,
  },
  {
    path: "*",
    element: <Error404Page />,
  },
  {
    path: "/shareddashboard",
    element: <SharedDashboardPage />,
  },
  {
    path: "/sharedmtpdashboard",
    element: <SharedMTPDashboardPage />,
  },
  {
    path: "/download063dashboard",
    element: 
    <DownloadProvider>
       <Download063Dashboard />
    </DownloadProvider>,
  },
  {
    path: "/downloadlbsdashboard",
    element: <DownloadLBSDashboard />,
  },
  {
    path: "/downloadclickwithdashboard",
    element: <DownloadClickwithDashboard />,
  },
  {
    path: "/downloadmtporgnarrative",
    element: <DownloadMTPOrgNarrative />,
  },
];
