/**
 * @Author: Anandp
 * @Date:   2024-04-17 18:40:50
 * @Last Modified by:   Anandp
 * @Last Modified time: 2024-12-23 22:07:50
 */
import axios from "axios";
import { Url } from "src/config";
import CryptoJS from 'crypto-js';
//Customer api

const customerApiService = {
  /**
   * api used to get all the countries to list down
   * @returns
   */
  getCountries: async () => {
    try {
      const response = await fetch(`${Url}/module/common/countries`);
      const data = await response.json();
      return data;
    } catch (error) {
      console.log(error.message);
    }
  },
  /**
   * api used to create Organization
   * @param {*} customerData 
   * @returns 
   */
  createCustomer: async (customerData, userId) => {
    try {
      const response = await axios.post(`${Url}/module/customer`, { customerData, userId });
      return response.data;
    } catch (error) {
      console.log(error.message);
    }
  },
  /**
   * api used to get all the Organization
   * @returns 
   */
  getCustomers: async () => {
    try {
      const response = await axios.get(`${Url}/module/customer`);
      return response.data;
    } catch (error) {
      console.log(error.message);
    }
  },
  /**
   * api used to get Organization with pagination
   * @param {*} currentPage 
   * @param {*} limit 
   * @returns 
   */
  getpaginatedOrganization: async (currentPage, limit, searchvalue) => {
    try {
      let search = ""
      if (searchvalue.length > 2) {
        search = `&search=${searchvalue}`
      }
      const response = await axios.get(`${Url}/module/getpaginatedorganization?page=${currentPage}&limit=${limit}${search}`)
      return response.data
    } catch (error) {
      console.log(error.message);
    }
  },
  /**
   * api used to get the selected Organization in ui
   * @param {*} id 
   * @returns 
   */
  getSelectedCustomer: async (id) => {
    try {
      const response = await axios.get(`${Url}/module/customer?id=${id}`);
      return response.data;
    } catch (error) {
      console.log(error.message);
    }
  },
  /**
   * api used to update the edited Organization
   * @param {*} data 
   * @param {*} id 
   * @returns 
   */
  updateCustomer: async (data, id) => {
    try {
      const response = await axios.put(`${Url}/module/customer/${id}`, data);
      return response.data;
    } catch (error) {
      console.log(error.message);
    }
  },
  /**
   * api used to update organization by making organization active and inactive
   * @param {*} id 
   * @param {*} updatedStatus 
   * @returns 
   */
  statusControlOrganization: async (id, updatedStatus) => {
    try {
      const response = await axios.post(`${Url}/module/statuscontrolorganization?id=${id}&status=${updatedStatus}`);
      return response.data;
    } catch (error) {
      console.log(error.message);
    }
  },
  updateJoyride: async (accessToken, data) => {
    try {
      const response = await axios.post(`${Url}/customer/postJoyrideStatus`, data, {
        headers: {
          "access-token": accessToken,
        }

      });
      return response.data;
    } catch (error) {
      console.log(error.message);
    }
  },
  getJoyride: async (accessToken,id) => {
    try {
      let apiUrl = `${Url}/customer/getjoyride`;
      const response = await axios.get(apiUrl, {
        headers: {
          "access-token": accessToken,
        },
      });
      const encrypted = CryptoJS.AES.encrypt(JSON.stringify(response.data.data), id.toString()).toString();
      // sessionStorage.setItem("surveyPerms", JSON.stringify(response.data.surveyIds));
      sessionStorage.setItem("joyrideStatus", encrypted);
      return response.data;
    } catch (error) {
      console.log(error.message);
    }
  },
}

export default customerApiService;
