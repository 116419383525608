/**
 * @Author: Anandp
 * @Date:   2024-04-17 18:40:50
 * @Last Modified by:   Anandp
 * @Last Modified time: 2024-12-23 22:00:03
 */
import axios from "axios";
import { Url } from "src/config";
import CryptoJS from 'crypto-js';
const mtpDashboardResultsApi = {

    getMTPdashboardResult: async (accessToken, teamId, duration, inviteId, level) => {
        try {
            let url = `${Url}/customer/getMTPdashboardResult?teamId=${teamId.join(',')}&duration=${duration}&level=${level}`;

            // Conditionally add inviteId if it is passed
            if (inviteId) {
                url += `&inviteId=${inviteId}`;
            }

            const response = await axios.get(url, {
                headers: {
                    "access-token": accessToken,
                }
            });

            return response.data;
        } catch (error) {
            console.log(error.message);
        }
    },
    getMTPShareddashboardResult: async (accessToken, teamId, duration, inviteId) => {
        try {
            let url = `${Url}/customer/getMTPShareddashboardResult?teamId=${teamId.join(',')}&duration=${duration}`;

            // Conditionally add inviteId if it is passed
            if (inviteId) {
                url += `&inviteId=${inviteId}`;
            }

            const response = await axios.get(url, {
                headers: {
                    "access-token": accessToken,
                }
            });

            return response.data;
        } catch (error) {
            console.log(error.message);
        }
    },
    getMTPTeamPermissions: async (accessToken, teamId, id) => {
        try {
            const response = await axios.get(
                `${Url}/customer/getteampermissions`,
                {
                    headers: {
                        "access-token": accessToken,
                    },
                    params: {
                        teamId: teamId.join(',')
                    }
                }
            );
            const encrypted = CryptoJS.AES.encrypt(JSON.stringify(response.data.data), id.toString()).toString();
            // sessionStorage.setItem("surveyPerms", JSON.stringify(response.data.surveyIds));
            sessionStorage.setItem("teamPerms", encrypted);
            return response.data;
        } catch (error) {
            console.log(error.message);
        }
    },
    getMTPSharedTeamPermissions: async (accessToken, teamId, id) => {
        try {
            const response = await axios.get(
                `${Url}/customer/getSharedteampermissions`,
                {
                    headers: {
                        "access-token": accessToken,
                    },
                    params: {
                        teamId: teamId.join(',')
                    }
                }
            );
            const encrypted = CryptoJS.AES.encrypt(JSON.stringify(response.data.data), id.toString()).toString();
            // sessionStorage.setItem("surveyPerms", JSON.stringify(response.data.surveyIds));
            sessionStorage.setItem("teamPerms", encrypted);
            return response.data;
        } catch (error) {
            console.log(error.message);
        }
    },
    getTeamPermissions: async (accessToken, teamId, id) => {
        try {
            const response = await axios.get(
                `${Url}/customer/getteampermissions`,
                {
                    headers: {
                        "access-token": accessToken,
                    },
                    params: {
                        teamId: teamId
                    }
                }
            );
            const encrypted = CryptoJS.AES.encrypt(JSON.stringify(response.data.data), id.toString()).toString();
            // sessionStorage.setItem("surveyPerms", JSON.stringify(response.data.surveyIds));
            sessionStorage.setItem("teamPerms", encrypted);
            window.dispatchEvent(new Event('storage'));
            return response.data;
        } catch (error) {
            console.log(error.message);
        }
    },
    fetchTeamNarrativeQuestions: async (accessToken, inviteId) => {
        try {
            const response = await axios.get(
                `${Url}/customer/getTeamNarrativeQuestions`,
                {
                    headers: {
                        "access-token": accessToken,
                    },
                    params: {
                        inviteId: inviteId,
                    }
                }
            );
            return response.data;
        } catch (error) {
            console.log(error.message);
        }
    },
    fetchSharedTeamNarrativeQuestions: async (accessToken, inviteId) => {
        try {
            const response = await axios.get(
                `${Url}/customer/getSharedTeamNarrativeQuestions`,
                {
                    headers: {
                        "access-token": accessToken,
                    },
                    params: {
                        inviteId: inviteId,
                    }
                }
            );
            return response.data;
        } catch (error) {
            console.log(error.message);
        }
    },
    fetchNarrativeQuestionAnswers: async (accessToken, inviteId, qusId, teamCId, tenanatId) => {
        try {
            const response = await axios.get(
                `${Url}/customer/getNarrativeQuestionAnswers`,
                {
                    headers: {
                        "access-token": accessToken,
                    },
                    params: {
                        inviteId: inviteId,
                        qusId: qusId,
                        teamId: teamCId.join(','),
                        tenanatId
                    },
                }
            );
            return response.data;
        } catch (error) {
            console.log(error.message);
        }
    },
    fetchSharedNarrativeQuestionAnswers: async (accessToken, inviteId, qusId, teamCId, tenanatId) => {
        try {
            const response = await axios.get(
                `${Url}/customer/getSharedNarrativeQuestionAnswers`,
                {
                    headers: {
                        "access-token": accessToken,
                    },
                    params: {
                        inviteId: inviteId,
                        qusId: qusId,
                        teamId: teamCId.join(','),
                        tenanatId: tenanatId
                    },
                }
            );
            return response.data;
        } catch (error) {
            console.log(error.message);
        }
    },
    getUserTeamTree: async (accessToken, id) => {
        try {
            const response = await axios.get(`${Url}/customer/getUserTeamTree`, {
                headers: {
                    "access-token": accessToken,
                },
                params: {
                    id: id
                },
            });
            return response.data;
        } catch (error) {
            return error
        }
    },
    getLeadershipPopupResult: async (accessToken, teamId, inviteId, categoryId) => {
        try {
            const response = await axios.get(
                `${Url}/customer/getLeadershipPopupResult`,
                {
                    headers: {
                        "access-token": accessToken,
                    },
                    params: {
                        teamId: teamId.join(','),
                        inviteId: inviteId,
                        categoryId: categoryId
                    }
                }
            );
            return response.data;
        } catch (error) {
            console.log(error.message);
        }
    },
    getSharedLeadershipPopupResult: async (accessToken, teamId, inviteId, categoryId) => {
        try {
            const response = await axios.get(
                `${Url}/customer/getSharedLeadershipPopupResult`,
                {
                    headers: {
                        "access-token": accessToken,
                    },
                    params: {
                        teamId: teamId.join(','),
                        inviteId: inviteId,
                        categoryId: categoryId
                    }
                }
            );
            return response.data;
        } catch (error) {
            console.log(error.message);
        }
    },
    getMojoPopupResult: async (accessToken, teamId, inviteId, categoryId) => {
        try {
            const response = await axios.get(
                `${Url}/customer/getMojoPopupResult`,
                {
                    headers: {
                        "access-token": accessToken,
                    },
                    params: {
                        teamId: teamId.join(','),
                        inviteId: inviteId,
                        categoryId: categoryId
                    }
                }
            );
            return response.data;
        } catch (error) {
            console.log(error.message);
        }
    },
    getSharedMojoPopupResult: async (accessToken, teamId, inviteId, categoryId) => {
        try {
            const response = await axios.get(
                `${Url}/customer/getSharedMojoPopupResult`,
                {
                    headers: {
                        "access-token": accessToken,
                    },
                    params: {
                        teamId: teamId.join(','),
                        inviteId: inviteId,
                        categoryId: categoryId
                    }
                }
            );
            return response.data;
        } catch (error) {
            console.log(error.message);
        }
    },
    getLowMojoMembers: async (accessToken, teamId, inviteId, categoryId) => {
        try {
            const response = await axios.get(
                `${Url}/customer/getlowmojomembers`,
                {
                    headers: {
                        "access-token": accessToken,
                    },
                    params: {
                        teamId: teamId.join(','),
                        inviteId: inviteId,
                        categoryId: categoryId
                    }
                }
            );
            return response.data.data;
        } catch (error) {
            console.log(error.message);
        }
    },
    getCulturePopupResult: async (accessToken, teamId, inviteId, categoryId) => {
        try {
            const response = await axios.get(
                `${Url}/customer/getCulturePopupResult`,
                {
                    headers: {
                        "access-token": accessToken,
                    },
                    params: {
                        teamId: teamId.join(','),
                        inviteId: inviteId,
                        categoryId: categoryId
                    }
                }
            );
            return response.data;
        } catch (error) {
            console.log(error.message);
        }
    },
    getRiskPopupResult: async (accessToken, teamId, inviteId, categoryId) => {
        try {
            const response = await axios.get(
                `${Url}/customer/getRiskPopupResult`,
                {
                    headers: {
                        "access-token": accessToken,
                    },
                    params: {
                        teamId: teamId.join(','),
                        inviteId: inviteId,
                        categoryId: categoryId
                    }
                }
            );
            return response.data;
        } catch (error) {
            console.log(error.message);
        }
    },
    getSharedCulturePopupResult: async (accessToken, teamId, inviteId, categoryId) => {
        try {
            const response = await axios.get(
                `${Url}/customer/getSharedCulturePopupResult`,
                {
                    headers: {
                        "access-token": accessToken,
                    },
                    params: {
                        teamId: teamId.join(','),
                        inviteId: inviteId,
                        categoryId: categoryId
                    }
                }
            );
            return response.data;
        } catch (error) {
            console.log(error.message);
        }
    },
    getSharedRiskPopupResult: async (accessToken, teamId, inviteId, categoryId) => {
        try {
            const response = await axios.get(
                `${Url}/customer/getSharedRiskPopupResult`,
                {
                    headers: {
                        "access-token": accessToken,
                    },
                    params: {
                        teamId: teamId.join(','),
                        inviteId: inviteId,
                        categoryId: categoryId
                    }
                }
            );
            return response.data;
        } catch (error) {
            console.log(error.message);
        }
    },
    getProductivityPopupResult: async (accessToken, teamId, inviteId, categoryId) => {
        try {
            const response = await axios.get(
                `${Url}/customer/getProductivityPopupResult`,
                {
                    headers: {
                        "access-token": accessToken,
                    },
                    params: {
                        teamId: teamId.join(','),
                        inviteId: inviteId,
                        categoryId: categoryId
                    }
                }
            );
            return response.data;
        } catch (error) {
            console.log(error.message);
        }
    },
    getSharedProductivityPopupResult: async (accessToken, teamId, inviteId, categoryId) => {
        try {
            const response = await axios.get(
                `${Url}/customer/getSharedProductivityPopupResult`,
                {
                    headers: {
                        "access-token": accessToken,
                    },
                    params: {
                        teamId: teamId.join(','),
                        inviteId: inviteId,
                        categoryId: categoryId
                    }
                }
            );
            return response.data;
        } catch (error) {
            console.log(error.message);
        }
    },
    getPromotersPopupResult: async (accessToken, teamId, inviteId, categoryId) => {
        try {
            const response = await axios.get(
                `${Url}/customer/getPromotersPopupResult`,
                {
                    headers: {
                        "access-token": accessToken,
                    },
                    params: {
                        teamId: teamId.join(','),
                        inviteId: inviteId,
                        categoryId: categoryId
                    }
                }
            );
            return response.data;
        } catch (error) {
            console.log(error.message);
        }
    },
    getSharedPromotersPopupResult: async (accessToken, teamId, inviteId, categoryId) => {
        try {
            const response = await axios.get(
                `${Url}/customer/getSharedPromotersPopupResult`,
                {
                    headers: {
                        "access-token": accessToken,
                    },
                    params: {
                        teamId: teamId.join(','),
                        inviteId: inviteId,
                        categoryId: categoryId
                    }
                }
            );
            return response.data;
        } catch (error) {
            console.log(error.message);
        }
    },
    getEngagementPopupResult: async (accessToken, teamId, inviteId, categoryId) => {
        try {
            const response = await axios.get(
                `${Url}/customer/getEngagementPopupResult`,
                {
                    headers: {
                        "access-token": accessToken,
                    },
                    params: {
                        teamId: teamId.join(','),
                        inviteId: inviteId,
                        categoryId: categoryId.join(',')
                    }
                }
            );
            return response.data;
        } catch (error) {
            console.log(error.message);
        }
    },
    getSharedEngagementPopupResult: async (accessToken, teamId, inviteId, categoryId) => {
        try {
            const response = await axios.get(
                `${Url}/customer/getSharedEngagementPopupResult`,
                {
                    headers: {
                        "access-token": accessToken,
                    },
                    params: {
                        teamId: teamId.join(','),
                        inviteId: inviteId,
                        categoryId: categoryId.join(',')
                    }
                }
            );
            return response.data;
        } catch (error) {
            console.log(error.message);
        }
    },
    getEffectivenessPopupResult: async (accessToken, teamId, inviteId, categoryId) => {
        try {
            const response = await axios.get(
                `${Url}/customer/getEffectivenessPopupResult`,
                {
                    headers: {
                        "access-token": accessToken,
                    },
                    params: {
                        teamId: teamId.join(','),
                        inviteId: inviteId,
                        categoryId: categoryId
                    }
                }
            );
            return response.data;
        } catch (error) {
            console.log(error.message);
        }
    },
    getSharedEffectivenessPopupResult: async (accessToken, teamId, inviteId, categoryId) => {
        try {
            const response = await axios.get(
                `${Url}/customer/getSharedEffectivenessPopupResult`,
                {
                    headers: {
                        "access-token": accessToken,
                    },
                    params: {
                        teamId: teamId.join(','),
                        inviteId: inviteId,
                        categoryId: categoryId
                    }
                }
            );
            return response.data;
        } catch (error) {
            console.log(error.message);
        }
    },
    getValuesPopup: async (accessToken, teamIds, inviteId) => {
        try {
            const response = await axios.get(
                `${Url}/customer/getValuesResult`,
                {
                    headers: {
                        "access-token": accessToken,
                    },
                    params: {
                        teamId: teamIds[0],
                        inviteId: inviteId
                    }
                }
            );
            return response.data;
        } catch (error) {
            console.log(error.message);
        }
    },
    getSharedValuesPopup: async (accessToken, teamIds, inviteId) => {
        try {
            const response = await axios.get(
                `${Url}/customer/getSharedValuesResult`,
                {
                    headers: {
                        "access-token": accessToken,
                    },
                    params: {
                        teamId: teamIds[0],
                        inviteId: inviteId
                    }
                }
            );
            return response.data;
        } catch (error) {
            console.log(error.message);
        }
    },
    getJsonData: async (accessToken, teamIds) => {
        try {
            const response = await axios.get(
                `${Url}/customer/share/mtp/getcsv`,
                {
                    headers: {
                        "access-token": accessToken,
                    },
                    params: {
                        teamId: teamIds.join(',')
                    }
                }
            );
            return response.data.content;
        } catch (error) {
            return error
        }
    },
    getORGJsonData: async (accessToken, teamIds) => {
        try {
            const response = await axios.get(
                `${Url}/customer/orgnarratives/getquestionsdownloaded`,
                {
                    headers: {
                        "access-token": accessToken,
                    },
                    params: {
                        teamId: teamIds.join(',')
                    }
                }
            );
            return response.data.data;
        } catch (error) {
            return error
        }
    },
    getVotPopup: async (accessToken, teamIds, invID, userId) => {
        try {
            const response = await axios.get(
                `${Url}/customer/getVoiceOfTeamResult`,
                {
                    headers: {
                        "access-token": accessToken,
                    },
                    params: {
                        teamId: teamIds.join(','),
                        inviteId: invID,
                        userId: userId
                    }
                }
            );
            return response.data;
        } catch (error) {
            return error
        }
    },
    getSharedVotPopup: async (accessToken, teamIds, invID, userId) => {
        try {
            const response = await axios.get(
                `${Url}/customer/getSharedVoiceOfTeamResult`,
                {
                    headers: {
                        "access-token": accessToken,
                    },
                    params: {
                        teamId: teamIds.join(','),
                        inviteId: invID,
                        userId: userId
                    }
                }
            );
            return response.data;
        } catch (error) {
            return error
        }
    },
    getTeamDynamicsResult: async (accessToken, teamId, dashboard) => {
        try {
            const response = await axios.get(
                `${Url}/customer/getTeamDynamicsResult`,
                {
                    headers: {
                        "access-token": accessToken,
                    },
                    params: {
                        teamId: teamId,
                        dashboard: dashboard
                    }
                }
            );
            return response.data.data.data;
        } catch (error) {
            console.log(error.message);
        }
    },
    get360mriSurveyResult: async (accessToken, teamId) => {
        try {
            const response = await axios.get(
                `${Url}/customer/get360mriSurveyResult`,
                {
                    headers: {
                        "access-token": accessToken,
                    },
                    params: {
                        teamId: teamId
                    }
                }
            );
            return response.data
        } catch (error) {
            console.log(error.message);
        }
    },
    getShared360mriSurveyResult: async (accessToken, teamId) => {
        try {
            const response = await axios.get(
                `${Url}/customer/getShared360mriSurveyResult`,
                {
                    headers: {
                        "access-token": accessToken,
                    },
                    params: {
                        teamId: teamId
                    }
                }
            );
            return response.data
        } catch (error) {
            console.log(error.message);
        }
    },
    get360mriCategoryResult: async (accessToken, teamId, categoryId, inviteId) => {
        try {
            const response = await axios.get(
                `${Url}/customer/get360CategoryData?teamId=${teamId}&categoryId=${categoryId}&inviteId=${inviteId}`,
                {
                    headers: {
                        "access-token": accessToken,
                    },

                }
            );
            return response.data
        } catch (error) {
            console.log(error.message);
        }
    },
    getShared360mriCategoryResult: async (accessToken, teamId, categoryId, inviteId) => {
        try {
            const response = await axios.get(
                `${Url}/customer/getShared360CategoryData?teamId=${teamId}&categoryId=${categoryId}&inviteId=${inviteId}`,
                {
                    headers: {
                        "access-token": accessToken,
                    },

                }
            );
            return response.data
        } catch (error) {
            console.log(error.message);
        }
    },
    getAllUsersResult: async (accessToken, teamId, categoryId, inviteId) => {
        try {
            const response = await axios.get(
                `${Url}/customer/allMembersScore?teamId=${teamId}&categoryId=${categoryId}&inviteId=${inviteId}`,
                {
                    headers: {
                        "access-token": accessToken,
                    },

                }
            );
            return response.data
        } catch (error) {
            console.log(error.message);
        }
    },
    postInviteShareLInk: async (htmlContent, SurveyId, accessToken) => {
        try {
            const response = await axios.post(
                `${Url}/customer/share/dashboard?surveyId=${SurveyId}`, htmlContent,
                {
                    headers: {
                        "access-token": accessToken,
                    }
                }
            );
            return response.data;
        } catch (error) {
            console.log(error.message);
        }
    },
    postLamdaShareLInk: async (htmlLink) => {
        try {
            // htmlLink='https://dev-customer.sl-ivl.com/shareddashboard?dG9rZW49ZXlKbGVIQnBjbVZ6U1c0aU9tNTFiR3g5LmV5SnhkV1YxWlVsa0lqb2laVEZrTUdFM1pEZ3RObU15TVMwME9EZzBMV0ZoWXpFdE1UQTNPR0ZoT1RFMk9ESTNJaXdpZFhObGNrbGtJam8xTmprMUxDSmxlSEJwY25sZlpHRjBaU0k2SWpJd01qVXRNVEF0TWpZZ01UTTZNRFk2TXpNaUxDSjBaVzVoYm5SZmFXUWlPamcwTm4wPS5VMDk0VEYwU0YzVmlFVlZUY1N0WVVCdG1CeXA0Y2dRTEhnVldOWHdwQ1RnUFlITUhEZ1VpQ0g1bWNsdHRPSGRCRG14MmNnNXlBRmtNRm5NRWZYQndkZ2d0VHkxZFlIWXRWRkl0V1FncldnUVBVaTl3ZlFKNEtDRUpBRU1nV1MwbkgzOTBBVzVnZlhSN1h3cFBlaXNVUVF0bEFqWjdYR3RUZEJFTWNuVUFZQTUxTzNwUURYRUxjVzlpZEZBbUwyTW5VeE1WS0ExWkpBY1BMM1VG'
            const response = await axios.get(
                `${Url}/html?url=${htmlLink}`
            );
            return response.data;
        } catch (error) {
            console.log('Error fetching Lambda share link:', error.response ? error.response.data : error.message);
            // throw error; // rethrow the error if needed
        }

    },
    postMTPInviteShareLInk: async (htmlContent, SurveyId, accessToken) => {
        try {
            const response = await axios.post(
                `${Url}/customer/share/MTPdashboard?surveyId=${SurveyId}`, htmlContent,
                {
                    headers: {
                        "access-token": accessToken,
                    }
                }
            );
            return response.data;
        } catch (error) {
            console.log(error.message);
        }
    },
    get360DashboardResult: async (accessToken, teamId) => {
        try {
            const response = await axios.get(
                `${Url}/customer/360Result?teamId=${teamId}`,
                {
                    headers: {
                        "access-token": accessToken,
                    },

                }
            );
            return response.data
        } catch (error) {
            console.log(error.message);
            return error;
        }
    },
    get360SharedDashboardResult: async (accessToken, teamId) => {
        try {
            const response = await axios.get(
                `${Url}/customer/360SharedResult?teamId=${teamId}`,
                {
                    headers: {
                        "access-token": accessToken,
                    },

                }
            );
            return response.data
        } catch (error) {
            console.log(error.message);
            return error;
        }
    },
    getMTPSharedTeamNames: async (accessToken, teamId) => {
        try {
            const response = await axios.get(
                `${Url}/customer/getMTPSharedTeamNames?teamId=${teamId}`,
                {
                    headers: {
                        "access-token": accessToken,
                    },

                }
            );
            return response.data
        } catch (error) {
            console.log(error.message);
            return error;
        }
    },
    getTeamLeaders: async (accessToken, teamId) => {
        try {
            const response = await axios.get(
                `${Url}/customer/mtpDashboard/teamleaders?team_id=${teamId}`,
                {
                    headers: {
                        "access-token": accessToken,
                    },

                }
            );
            return response.data
        } catch (error) {
            console.log(error.message);
            return error;
        }
    },
    sendEmail: async (Content, accessToken, inviteId) => {
        try {
            const response = await axios.post(
                `${Url}/customer/sendemail?inviteId=${inviteId}&emailid=56`, Content,
                {
                    headers: {
                        "access-token": accessToken,
                    }
                }
            );
            return response.data;
        } catch (error) {
            console.log(error.message);
        }
    },
    sendSICEmail: async (Content, accessToken, id) => {
        try {
            const response = await axios.post(
                `${Url}/customer/sendsicemail?emailid=57&queueId=${id}`, Content,
                {
                    headers: {
                        "access-token": accessToken,
                    }
                }
            );
            return response.data;
        } catch (error) {
            console.log(error.message);
        }
    },
    ISLUsersMTPDashboard: async (accessToken, userId) => {
        try {
            const response = await axios.get(
                `${Url}/customer/mtpDashboard/ISLUser?userId=${userId}`,
                {
                    headers: {
                        "access-token": accessToken,
                    },

                }
            );
            return response.data;
        } catch (error) {
            console.log(error.message);
            return error
        }
    },
    ISLUsersSharedMTPDashboard: async (accessToken, userId) => {
        try {
            const response = await axios.get(
                `${Url}/customer/mtpDashboard/ISLUserShared?userId=${userId}`,
                {
                    headers: {
                        "access-token": accessToken,
                    },

                }
            );
            return response.data;
        } catch (error) {
            console.log(error.message);
            return error
        }
    },
    sharedMTPCheckActiveDashboard: async (accessToken) => {
        try {
            const response = await axios.get(
                `${Url}/customer/share/MTPCheckActive`,
                {
                    headers: {
                        "access-token": accessToken,
                    },

                }
            );
            return response.data;
        } catch (error) {
            console.log(error.message);
            return error
        }
    },
    putNarratives: async (body, id, accessToken) => {
        try {
            const response = await axios.post(
                `${Url}/customer/mtpDashboard/editnarrative?pid=${id}`, body,
                {
                    headers: {
                        "access-token": accessToken,
                    }
                }
            );
            return response.data;
        } catch (error) {
            console.log(error.message);
        }
    },
    delNarratives: async (id, accessToken) => {
        try {
            const response = await axios.post(
                `${Url}/customer/mtpDashboard/delnarrative?pid=${id}`, [],
                {
                    headers: {
                        "access-token": accessToken,
                    }
                }
            );
            return response.data;
        } catch (error) {
            console.log(error.message);
        }
    },
    starNarratives: async (id, accessToken) => {
        try {
            const response = await axios.post(
                `${Url}/customer/mtpDashboard/starnarrative?pid=${id}`, [],
                {
                    headers: {
                        "access-token": accessToken,
                    }
                }
            );
            return response.data;
        } catch (error) {
            console.log(error.message);
        }
    },
    fetchTips360: async (accessToken, inviteId) => {
        try {
            const response = await axios.get(
                `${Url}/customer/get360tips?inviteId=${inviteId}`,
                {
                    headers: {
                        "access-token": accessToken,
                    },

                }
            );
            return response.data;
        } catch (error) {
            console.log(error.message);
            return error
        }
    },
    fetchSharedTips360: async (accessToken, inviteId) => {
        try {
            const response = await axios.get(
                `${Url}/customer/getShared360tips?inviteId=${inviteId}`,
                {
                    headers: {
                        "access-token": accessToken,
                    },

                }
            );
            return response.data;
        } catch (error) {
            console.log(error.message);
            return error
        }
    },
}



export default mtpDashboardResultsApi;