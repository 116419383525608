/**
 * @Author: Anandp
 * @Date:   2024-04-17 18:40:50
 * @Last Modified by:   Anandp
 * @Last Modified time: 2024-12-23 13:21:52
 */
import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import { createTypography } from "src/theme/base/customTypography";
// import RemoveIcon from '@mui/icons-material/Remove';
// import PlusBUtton from '@mui/icons-material/Add';

import {
  Checkbox,
  Typography,
  FormControlLabel,
  Accordion,
  AccordionSummary,
  // AccordionDetails,
} from "@mui/material";
import { useUser } from "src/hooks/use-user";
import { useEffect } from "react";
// import teamDashboardResultsApi from "src/services/teamdashboardApi";
import mtpDashboardResultsApi from "src/services/mtpDashboardApi";
import { useState } from "react";
import { useTeam } from "src/contexts/mtp-team-context";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import { Scrollbar } from "src/components/scrollbar";
// import { toast } from "react-hot-toast";
import { toast } from 'react-toastify';
import * as SldButtons from "src/theme/base/SldButtons";
import { useSidebar } from 'src/contexts/SidebarContext'; // Import the context hook
import { OutlinedInput, InputAdornment } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import SvgIcon from "@mui/material/SvgIcon";
// import MTPApiService from "src/services/mtpAPI";
import { RichTreeView } from '@mui/x-tree-view/RichTreeView';
import { useTreeViewApiRef } from '@mui/x-tree-view/hooks';
import { TreeItem, treeItemClasses } from '@mui/x-tree-view/TreeItem';
import { styled } from '@mui/material/styles';
import { useRef } from "react";

const SIDE_NAV_WIDTH = 210;
const SIDE_NAV_MINIMIZED_WIDTH = 50; // Adjust the width for minimized state
const typeAbbreviations = {
  Division: '(D)',
  'Sub Division': '(SD)',
  Team: '(T)',
};

/**
* Recursively converts a division, subdivision, or team structure into a node format
* usable by a tree view.
*/
function convertToTreeNode(item) {
  const { name, division_category_id, type_name, assigned } = item;
  const node = {
    id: division_category_id?.toString(),
    label: `${name} ${typeAbbreviations[type_name] || ''}`,
    type_name,
  };

  if (typeof assigned !== 'undefined') {
    node.assigned = assigned;
  }

  const children = [];

  // Recursively process subDivisions
  if (Array.isArray(item.subDivisions)) {
    for (const sub of item.subDivisions) {
      children.push(convertToTreeNode(sub));
    }
  }

  // Process teams
  if (Array.isArray(item.teams)) {
    for (const team of item.teams) {
      children.push(convertToTreeNode(team));
    }
  }

  if (children.length > 0) {
    node.children = children;
  }

  return node;
}

/**
* Converts the entire originalData, which may contain divisions and unassignedTeams, into a format
* suitable for a tree component.
*
* Unassigned teams are placed directly at the root level, alongside divisions.
*/
function convertData(originalData) {
  const divisionNodes = (originalData.divisions || []).map(div => convertToTreeNode(div));
  const unassignedTeamsNodes = (originalData.unassignedTeams || []).map(team => convertToTreeNode(team));

  // Combine divisions and unassigned teams at the same root level
  return [...divisionNodes, ...unassignedTeamsNodes];
}
export default function TemporaryDrawer(props) {
  const setDrawerOpen = props.setDrawerOpen;
  const typography = createTypography();
  const user = useUser();
  const accessToken = sessionStorage.getItem("accessToken");
  const { isMinimized } = useSidebar();
  const [searchData, setSearchData] = useState("");

  const [isLoading, setIsLoading] = useState(true);
  const {
    selectedTeamId,
    setTeamId,
    // eslint-disable-next-line
    // removeTeamId,
    applySelectedTeams,
    // unApplySelectedTeams,
    removeAllTeamIds,
    settingOrgLevelStateTrue,
    settingOrgLevelStateFalse,
    settingOrgName,
    orgLevel
  } = useTeam();
// eslint-disable-next-line
  const [teamsList, setTeamsList] = useState([]);
  const [organisationName, setOrganisationName] = useState();
  // eslint-disable-next-line
  const [selectedDivisionIds, setSelectedDivisionIds] = useState([]);
  const [tree, setTree] = useState({});
  const [originalTree, setOriginalTree] = useState({});
  const toggledItemRef = useRef({});
  const apiRef = useTreeViewApiRef();
  // eslint-disable-next-line
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [teamDetails, setTeamDetails] = useState([]);
  // const [renderKey, setRenderKey] = useState(0);
  const transformedData = convertData(tree);

  const CustomTreeItem = styled(TreeItem)(({ theme }) => ({
    [`& .${treeItemClasses.label}`]: {
      color: 'white', // Set label color to white
    },
    [`& .${treeItemClasses.iconContainer}`]: {
      color: 'white', // Set expand icon color to white
    },
  }));

  const [expandedItems, setExpandedItems] = useState([]);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      // callFunction(); // Call your function here
    }
  };
  // Function to extract all IDs recursively
  const getAllIds = (items) => {
    let ids = [];
    items.forEach(item => {
      ids.push(item.id);
      if (item.children) {
        ids = ids.concat(getAllIds(item.children));
      }
    });
    return ids;
  };
  const gatherAllItemIds = (items) => {
    let allItemIds = [];
    items.forEach((item) => {
      allItemIds.push(item.id);
      if (item.children && item.children.length > 0) {
        allItemIds = [...allItemIds, ...gatherAllItemIds(item.children)];
      }
    });
    return allItemIds;
  };

  // Select all items
  const selectAll = () => {
    settingOrgLevelStateTrue();
    const allItemIds = gatherAllItemIds(transformedData); // Get all IDs from transformed data
    setSelectedItems(allItemIds); // Update selected items state
    setIsAllSelected(true); // Update the checkbox state to checked
    updateTeamDetails(allItemIds); // Update related details
  };

  // Unselect all items
  const unselectAll = () => {
    setSelectedItems([]); // Clear the selected items
    setIsAllSelected(false); // Update the checkbox state to unchecked
    updateTeamDetails([]); // Update related details

    // Remove all team IDs and reset organization-level state
    removeAllTeamIds();
    settingOrgLevelStateFalse();

    // Clear the selected items and update the parent selection state
    setSelectedItems([]);
    updateParentSelectionState([]); // Update parent selection to reflect unselection

    // Clear team details
    setTeamDetails([]);
  };

  // Toggle selection based on checkbox state
  const handleOrgCheckboxChange = (event) => {
    if (event.target.checked) {
      selectAll();
    } else {
      unselectAll();
    }
  };

  // Effect to update checkbox state based on selected items
  useEffect(() => {
    const allItemIds = gatherAllItemIds(transformedData);
    setIsAllSelected(selectedItems.length === allItemIds.length);
    // eslint-disable-next-line
  }, [selectedItems]);

  /**
   * Filters a tree structure based on a search term. The tree consists of divisions, subdivisions, and teams, and the function
   * will match against team names, subdivision names, and division names.
   *
   * @function filterTree
   * @param {Object} tree - The root tree structure containing divisions and subdivisions.
   * @param {string} searchTerm - The term to search for within team, subdivision, and division names.
   * @returns {Object} - A new tree structure with divisions, subdivisions, and teams filtered according to the search term.
   *
   * @description
   * The function recursively filters the tree structure based on the provided search term. The search is case-insensitive and
   * matches any division, subdivision, or team name that contains the search term. It includes:
   * - **Teams**: Teams are filtered by their names.
   * - **SubDivisions**: SubDivisions are filtered by their names and their contained teams or subdivisions.
   * - **Divisions**: Divisions are filtered by their names and their contained teams or subdivisions.
   *
   * - The function ensures that any subdivisions or teams within a division that match the search term will be included.
   * - Only divisions with at least one matching team, subdivision, or a matching name will be included in the filtered result.
   * 
   * @example
   * const tree = {
   *   divisions: [
   *     {
   *       name: "Division 1",
   *       teams: [{ name: "Team A" }, { name: "Team B" }],
   *       subDivisions: [{ name: "SubDivision 1", teams: [{ name: "Team C" }] }]
   *     },
   *     {
   *       name: "Division 2",
   *       teams: [{ name: "Team D" }],
   *       subDivisions: []
   *     }
   *   ]
   * };
   * const searchTerm = "team a";
   * const filteredTree = filterTree(tree, searchTerm);
   * console.log(filteredTree); 
   * // The output will contain only divisions and teams that match "team a"
   */
  const filterTree = (tree, searchTerm) => {
    const filterNode = (node) => {
      let matchedTeams = [];
      if (node.teams) {
        matchedTeams = node.teams.filter(team =>
          team.name.toLowerCase().includes(searchTerm.toLowerCase())
        );
      }

      let matchedSubDivisions = [];
      if (node.subDivisions) {
        matchedSubDivisions = node.subDivisions
          .map(subDiv => filterNode(subDiv))
          .filter(subDiv => subDiv.teams.length > 0 || subDiv.subDivisions.length > 0 || subDiv.name.toLowerCase().includes(searchTerm.toLowerCase()));
      }

      const nodeMatches = node.name.toLowerCase().includes(searchTerm.toLowerCase());

      return {
        ...node,
        teams: matchedTeams,
        subDivisions: matchedSubDivisions,
        match: nodeMatches || matchedTeams.length > 0 || matchedSubDivisions.length > 0
      };
    };

    const filteredDivisions = tree.divisions
      .map(division => filterNode(division))
      .filter(division => division.match);

    return {
      ...tree,
      divisions: filteredDivisions
    };
  };
  useEffect(() => {
    if (searchData === '') {
      setTree(originalTree);
    } else {
      if (tree?.divisions?.length > 0) {
        const filteredTree = filterTree(originalTree, searchData);
        setTree(filteredTree);
      }
    }
    // eslint-disable-next-line
  }, [searchData, originalTree]);
  useEffect(() => {
    if (transformedData.length > 0) {
      const allIds = getAllIds(transformedData);
      setExpandedItems(allIds);
    }
    // eslint-disable-next-line
  }, [searchData.length > 0]);

  /**
   * Retrieves the ancestor IDs of a given item based on its ID. The ancestors are collected from the root to the immediate parent.
   *
   * @function getAncestorIds
   * @param {string} itemId - The ID of the item whose ancestors are to be fetched.
   * @returns {Array} - An array containing the ancestor IDs, ordered from the root to the immediate parent.
   *
   * @description
   * This function recursively finds and returns the ancestors of a given item. It traverses the tree structure, starting from
   * the given item's ID and working upwards to find its ancestors, all the way to the root. The ancestors are collected in
   * an array, which is returned by the function.
   * 
   * - **Recursive Parent Lookup**: The function starts from the provided item ID and finds its immediate parent. 
   * - **Ancestor Collection**: The ancestor IDs are collected in the order from the root to the immediate parent.
   * 
   * @example
   * const itemId = "team_1";
   * const ancestors = getAncestorIds(itemId);
   * console.log(ancestors); // ["division_1", "root"]
   * // This will return all ancestor IDs for the item with ID "team_1".
   */
  const getAncestorIds = (itemId) => {
    const ancestors = [];

    const findParent = (currentId, items) => {
      for (const item of items) {
        if (item.children && item.children.some((child) => child.id === currentId)) {
          ancestors.push(item.id);
          findParent(item.id, transformedData); // Check parent of this parent
          return;
        }

        if (item.children) {
          findParent(currentId, item.children);
        }
      }
    };

    findParent(itemId, transformedData);
    return ancestors;
  };

  /**
   * Initializes the selection of items, including their parents, based on a given list of selected team IDs. 
   * It ensures that if all children of a parent are selected, the parent is also selected.
   *
   * @function initializeSelectionWithParents
   * @param {Array} selectedTeamIds - An array of team IDs that are initially selected.
   * @returns {void} - This function updates the selection state by including the parent items if all of their children are selected.
   *
   * @description
   * This function initializes the selection by processing a list of selected team IDs. It ensures that when an item’s 
   * children are all selected, the item’s parent is also selected. The function uses recursion to process each item 
   * and its descendants, adding parents to the selection if all their children are selected.
   *
   * - **Recursive Item Processing**: The function traverses the tree structure, processing items and their children.
   * - **Parent Selection**: If all children of a parent item are selected, the parent is automatically selected.
   * - **State Update**: After processing, the `selectedItems` state is updated with the finalized selection.
   * 
   * @example
   * const selectedTeamIds = ["team_1", "team_2"];
   * initializeSelectionWithParents(selectedTeamIds);
   * // This will process the selection and ensure parents are selected if all their children are selected.
   */
  const initializeSelectionWithParents = (selectedTeamIds) => {
    const selectedSet = new Set(selectedTeamIds);

    // Helper function to check if all children are selected
    const areAllChildrenSelected = (item) => {
      const children = getChildrenItems(item);
      const result = children.length > 0 && children.every((child) => selectedSet.has(child.id));
      return result;
    };

    // Recursive function to process items
    const processItem = (item) => {
      const children = getChildrenItems(item);

      // Process children first
      children.forEach((child) => processItem(child));

      // Check if all children are selected and add the parent if needed
      if (areAllChildrenSelected(item)) {
        selectedSet.add(item.id);
      } else {
      }
    };

    // Start processing each top-level item
    transformedData.forEach((item) => {
      processItem(item);
    });

    const finalSelectedItems = Array.from(selectedSet);

    // Update states with the finalized selection
    setSelectedItems(finalSelectedItems);
    updateTeamDetails(finalSelectedItems);
  };

  useEffect(() => {
    const transformedData = convertData(tree);

    if (
      selectedTeamId &&
      selectedTeamId.length > 0 &&
      transformedData.length > 0
    ) {
      initializeSelectionWithParents(selectedTeamId);
    }
    // eslint-disable-next-line
  }, [selectedTeamId, tree]);
  const getItemDescendantsIds = (item) => {
    const ids = [];
    item.children?.forEach((child) => {
      ids.push(child.id);
      ids.push(...getItemDescendantsIds(child));
    });
    return ids;
  };

  /**
   * Fetches the user's team tree data and updates the state accordingly.
   *
   * @function fetchUsersTeams
   * @returns {Promise<void>} Resolves when the data is fetched and the state is updated.
   *
   * @description
   * This function fetches the user's team tree data from the API and updates various states based on the response:
   * - If the response contains a message, it displays an error message using `toast.error`.
   * - If the response is successful, it updates:
   *   - `teamsList` with the fetched team tree.
   *   - `organisationName` with the organisation name.
   *   - `settingOrgName` with the organisation name from the response.
   *   - `tree` and `originalTree` with the fetched team data.
   * 
   * It also handles loading states by setting `isLoading` to false once the data is successfully fetched or if there is an error.
   *
   * @example
   * // Fetch teams and update states
   * fetchUsersTeams();
   */
  const fetchUsersTeams = async () => {
    try {
      const response = await mtpDashboardResultsApi.getUserTeamTree(
        accessToken,
        user.id
      );
      if (response.message) {
        toast.error(response.message)
        setIsLoading(false);
      } else {
        setTeamsList(response.teamTree);
        setOrganisationName(response.organisationName[0]);
        settingOrgName(response.organisationName[0].OrganisationName);
        setIsLoading(false);
        setIsLoading(false);
        setTree(response.data);
        setOriginalTree(response.data);
      }

    } catch (error) {
      console.log("error", error);
    }
  };


  const getChildrenItems = (item) => {
    const children = item.children || [];
    return children;
  };

  const handleItemSelectionToggle = (event, itemId, isSelected) => {
    toggledItemRef.current[itemId] = isSelected;
  };

  /**
   * Handles the change in selected items and updates the selection state accordingly, 
   * including descendants and ancestors of the selected/unselected items.
   *
   * @function handleSelectedItemsChange
   * @param {Object} event - The event object triggered by the selection change (usually from a UI component).
   * @param {Array} newSelectedItems - An array of item IDs representing the newly selected items.
   * @returns {void} - This function updates the selection state by selecting or unselecting items and their descendants/ancestors.
   *
   * @description
   * This function manages the state change when an item’s selection is toggled. It updates the selected items 
   * by considering both descendants of selected items and ancestors/descendants of unselected items. 
   * Additionally, it updates the parent selection state to maintain consistency.
   *
   * - **Selecting an Item**: If an item is selected, all its descendants are also selected.
   * - **Unselecting an Item**: If an item is unselected, its descendants and ancestors are unselected.
   * - **State Update**: The function ensures that the `selectedItems` state is updated based on the current selection, 
   *   including the new selections and removing unselected items.
   * 
   * @example
   * const newSelectedItems = ["team_1", "subdivision_2"];
   * handleSelectedItemsChange(event, newSelectedItems);
   * // This will update the selected items, including descendants of "team_1" and unselecting any unselected items' descendants.
   */
  const handleSelectedItemsChange = (event, newSelectedItems) => {
    const itemsToSelect = [];
    const itemsToUnselect = {};

    Object.entries(toggledItemRef.current).forEach(([itemId, isSelected]) => {
      const item = apiRef.current.getItem(itemId);
      if (isSelected) {
        // Include descendants of this item
        itemsToSelect.push(...getItemDescendantsIds(item));
      } else {
        // Unselect this item’s descendants and its ancestors
        getItemDescendantsIds(item).forEach((descendantId) => {
          itemsToUnselect[descendantId] = true;
        });
        getAncestorIds(itemId).forEach((ancestorId) => {
          itemsToUnselect[ancestorId] = true;
        });
      }
    });

    const newSelectedItemsWithChildren = Array.from(
      new Set([...newSelectedItems, ...itemsToSelect])
    ).filter((itemId) => !itemsToUnselect[itemId]);

    setSelectedItems(newSelectedItemsWithChildren);

    toggledItemRef.current = {};

    // Update parent selection state for consistency
    updateParentSelectionState(newSelectedItemsWithChildren);
  };

  /**
   * Updates the parent selection state based on the currently selected items and their children.
   *
   * @function updateParentSelectionState
   * @param {Array} selectedItems - An array of selected item IDs to initialize the selection state.
   * @returns {void} - This function does not return a value, it updates the selection state of parents based on child selection.
   *
   * @description
   * This function updates the selection state for parent items based on whether all their children are selected. 
   * If all the children of a parent item are selected, the parent item is also marked as selected.
   * Additionally, the function propagates the selection state upwards for all ancestors of the selected items.
   *
   * - The `updateParentState` function recursively checks each item’s children and ensures that the parent is selected only if all its children are selected.
   * - The `propagateParentSelectionUpwards` function ensures that the selection state of an ancestor is updated based on its children’s selection.
   *
   * @example
   * const selectedItems = ["team_1", "team_2", "team_3"];
   * updateParentSelectionState(selectedItems);
   * // This will update the selected state of parent items based on the children selections.
   */
  const updateParentSelectionState = (selectedItems) => {
    const updatedSelectedItems = {};

    // Mark all currently selected items
    selectedItems.forEach((itemId) => {
      updatedSelectedItems[itemId] = true;
    });

    const updateParentState = (items) => {
      items.forEach((item) => {
        const children = getChildrenItems(item);

        if (children.length > 0) {
          // Check if all children are selected
          const allChildrenSelected = children.every((child) => updatedSelectedItems[child.id]);

          // Only mark the parent as selected if all children are selected
          updatedSelectedItems[item.id] = allChildrenSelected;

          // Recursively process children
          updateParentState(children);
        }
      });
    };

    // Start updating states from the root level
    updateParentState(transformedData);

    const propagateParentSelectionUpwards = (itemId) => {
      const ancestors = getAncestorIds(itemId);
      ancestors.forEach((ancestorId) => {
        const ancestor = findItemById(transformedData, ancestorId);
        const children = getChildrenItems(ancestor);

        // Select the ancestor only if all its children are selected
        if (children.every((child) => updatedSelectedItems[child.id])) {
          updatedSelectedItems[ancestor.id] = true;
        } else {
          updatedSelectedItems[ancestor.id] = false;
        }
      });
    };

    // Apply upward propagation for all selected items
    Object.keys(updatedSelectedItems).forEach((itemId) => {
      propagateParentSelectionUpwards(itemId);
    });

    const finalSelectedItems = Object.keys(updatedSelectedItems).filter((itemId) => updatedSelectedItems[itemId]);
    setSelectedItems(finalSelectedItems);
    updateTeamDetails(finalSelectedItems);
  };

  // Helper function to find an item by ID in a nested structure
  const findItemById = (items, id) => {
    for (const item of items) {
      if (item.id === id) return item;
      if (item.children) {
        const found = findItemById(item.children, id);
        if (found) return found;
      }
    }
    return null;
  };

  useEffect(() => {
    // if()
    // getTree();
    fetchUsersTeams();
    setIsLoading(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Converts the selected IDs into a specific array format by traversing a hierarchical JSON structure.
   *
   * @function convertToTypeArray
   * @param {Array} jsonData - The hierarchical data to be traversed, typically an array of root nodes.
   * @param {Array} selectedIds - The array of selected IDs to be included in the final result.
   * @returns {Array} - An array containing the selected IDs, formatted as `typeName_id`, where `typeName` is the `type_name` converted to camelCase.
   *
   * @description
   * This function traverses the provided `jsonData` and checks if each node's `id` is included in the `selectedIds` array.
   * If the node's ID is selected, it converts the node's `type_name` property to camelCase format and appends the `id` to create a formatted string `typeName_id`, which is then added to the result array.
   *
   * - The `toCamelCase` helper function is used to convert the `type_name` to camelCase format.
   * - The function recursively traverses the `jsonData` structure and processes all child nodes.
   * 
   * @example
   * const jsonData = [
   *   { id: 1, type_name: "First Type", children: [{ id: 2, type_name: "Second Type" }] }
   * ];
   * const selectedIds = [1, 2];
   * const result = convertToTypeArray(jsonData, selectedIds);
   * console.log(result); // ["firstType_1", "secondType_2"]
   */
  function convertToTypeArray(jsonData, selectedIds) {
    const result = [];

    function toCamelCase(str) {
      return str
        .split(' ')
        .map((word, index) =>
          index === 0
            ? word.toLowerCase()
            : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        )
        .join('');
    }

    function traverse(node) {
      if (selectedIds.includes(node.id)) {
        // Convert type_name to camelCase and append id
        const camelCaseTypeName = toCamelCase(node.type_name);
        result.push(`${camelCaseTypeName}_${node.id}`);
      }
      if (node.children && node.children.length > 0) {
        node.children.forEach(child => traverse(child));
      }
    }

    jsonData.forEach(rootNode => traverse(rootNode));
    return result;
  }

  /**
   * Updates the team details based on the selected items and their type.
   *
   * @function updateTeamDetails
   * @param {Array} selectedItems - The array of selected items (either team IDs or unassigned team IDs).
   * @param {string} type - The type of the selection, used to determine how to process the selected items ('org' or other types).
   * @returns {Promise} A promise that resolves when the team details have been updated.
   *
   * @description
   * This function updates the team details based on the selected items passed to it. The `type` parameter determines how the items should be processed:
   * - If the type is "org", the `selectedItems` are used directly.
   * - For other types, the function converts the `selectedItems` using a helper function `convertToTypeArray`.
   *
   * The function processes the selected team items by:
   * - Filtering the items that start with either 'team_' or 'unassigned_team_'.
   * - Mapping the filtered items to an object containing `teamId` and `teamName`, which is fetched from `apiRef` based on the `teamId`.
   * - Updating the `teamDetails` state with the processed information.
   *
   * @example
   * // Update the team details for selected items with the type 'org'
   * updateTeamDetails(selectedItems, 'org').then(() => {
   *   console.log("Team details updated");
   * });
   */
  const updateTeamDetails = (selectedItems, type) => {
    return new Promise((resolve) => {

      // Check if the selected items are being converted as expected
      const result = type === "org"
        ? selectedItems
        : convertToTypeArray(transformedData, selectedItems);

      // Filter teams and map details
      const updatedTeamDetails = result
        .filter((itemId) => itemId.startsWith('team_') || itemId.startsWith('unassigned_team_'))
        .map((itemId) => {
          const teamId = itemId.replace('team_', '').replace('unassigned_', '');
          const item = apiRef?.current?.getItem(teamId);

          // Ensure item is valid and has the necessary properties

          return { teamId, teamName: item?.label || "Unknown" };
        });

      // Update state and resolve
      setTeamDetails(updatedTeamDetails);
      resolve();
    });
  };

  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    // Check if the click event originated from within a checkbox
    if (event.target.tagName === "INPUT" && event.target.type === "checkbox") {
      return;
    }

    setState({ ...state, [anchor]: open });
  };


  /**
   * Handles the submission of the team selection and updates the state accordingly.
   *
   * @function handleSubmit
   * @returns {void}
   *
   * @description
   * This function is called when the user submits their team selection:
   * - Clears any previous team selections by calling `removeAllTeamIds`.
   * - If the `teamDetails` array has data, it:
   *   - Iterates over each team and calls `setTeamId` to update the selected team with the team ID and team name.
   *   - Updates the state (`left` set to `false`).
   *   - Closes the drawer by setting `drawerOpen` to `false`.
   *   - Applies the selected teams by calling `applySelectedTeams`.
   * - If `teamDetails` is empty, no further action is taken.
   *
   * @example
   * // Submit the selected teams and update the state
   * handleSubmit();
   */
  const handleSubmit = () => {
    removeAllTeamIds(); // Clear previous selections
    if (teamDetails && teamDetails.length > 0) {
      teamDetails.forEach((team) => {
        setTeamId(team.teamId, team.teamName, '');
      });
      setState({ ...state, left: false });
      setDrawerOpen(false);
      applySelectedTeams(); // Apply final selection
    } else {
    }
  };

  const list = (anchor) => (
    <Box
      sx={{
        width: anchor === "top" || anchor === "bottom" ? "auto" : { lg: '310px', md: '310px', sm: '280px', xs: '280px' },
        height: "100%",
        backgroundColor: "#583F99",
        borderRadius: '0 15px 15px 0',
        zIndex: 1201,
        paddingLeft: { lg: '45px', md: '45px', sm: '5px', xs: '5px' },
        paddingTop: '25px',
        '& .MuiAccordionSummary-content .MuiFormControlLabel-root': { marginLeft: '0!important' },
        '& .MuiButtonBase-root': { padding: '4px 3px!important' }


      }}
      role="presentation"
      onClick={(event) => {
        if (event.target !== event.currentTarget) return; // Don't close on input events
        toggleDrawer(anchor, false);
      }}
      onKeyDown={(event) => {
        if (event.target !== event.currentTarget) return; // Don't close on input events
        toggleDrawer(anchor, false);
      }}
      p={2}
    >
      <Box
        component="form"
        sx={{
          flexGrow: 1,
          marginTop: 1,
          marginLeft: 1,
          marginRight: 1,
          marginBottom: 1,

        }}
      >
        <Typography
          sx={{ ...typography.subtitle.regularColorless, color: "white", marginBottom: '5px', fontSize: '13px' }}
        >
          Multi-Team Selector
        </Typography>

        <OutlinedInput
          value={searchData}
          autoFocus
          fullWidth
          onKeyDown={handleKeyDown}
          sx={{
            '& .MuiInputBase-input': { padding: '8px 0!important' },
            '&.MuiInputBase-root': { backgroundColor: '#fff!important' },
            '& .MuiInputAdornment-root path': { fill: '#535B66' },
            '& .MuiSvgIcon-root': { width: '16px', height: '16px' },
            ' input::placeholder': { color: '#535B66', fontSize: '12px' }

          }}
          placeholder="Search"
          onChange={(e) => setSearchData(e.target.value)}
          startAdornment={
            <InputAdornment position="start"
            >
              <SvgIcon>
                <SearchIcon fontSize="small" />
              </SvgIcon>
            </InputAdornment>
          }
        />
      </Box>


      <Scrollbar style={{ maxHeight: '100%', width: '100%' }}
        sx={{
          '& .MuiTreeItem-content': { gap: '0px', padding: '2px!important', alignItems: 'flex-start' },
          '& .MuiButtonBase-root': { padding: '2px' },
          '& .MuiButtonBase-root svg': { height: '18px' },
          '& .MuiTreeItem-label': { fontSize: '13px!important', lineHeight: '16px' },
          '& .MuiButtonBase-root.MuiCheckbox-root': { padding: '0!important' },


        }}>
        {organisationName && (
          <List>

            <div >
              <Accordion
                sx={{ backgroundColor: "transparent", }}>
                <AccordionSummary

                >
                  <FormControlLabel
                    label={
                      <Typography
                        sx={{
                          ...typography.subtitle.regularColorless,
                          color: "white",

                        }}
                      >
                        {organisationName.OrganisationName}
                      </Typography>
                    }
                    control={
                      <Checkbox
                        checked={orgLevel}
                        onChange={handleOrgCheckboxChange} 
                      />
                    
                    }
                  />
                </AccordionSummary>

              </Accordion>
            </div>

          </List>
        )}
        {searchData.length > 0 ?
          <RichTreeView
            // key={renderKey}
            apiRef={apiRef}
            multiSelect
            checkboxSelection
            items={transformedData}
            selectedItems={selectedItems}
            onSelectedItemsChange={handleSelectedItemsChange}
            onItemSelectionToggle={handleItemSelectionToggle}
            slots={{ item: CustomTreeItem }}
            expandedItems={expandedItems ? expandedItems : []}
            onExpandedItemsChange={undefined}
          /> :
          <RichTreeView
            // key={renderKey}
            apiRef={apiRef}
            multiSelect
            checkboxSelection
            items={transformedData}
            selectedItems={selectedItems}
            onSelectedItemsChange={handleSelectedItemsChange}
            onItemSelectionToggle={handleItemSelectionToggle}
            slots={{ item: CustomTreeItem }}
          />
        }

      </Scrollbar>
      <div style={{ alignSelf: "center" }}>
        <SldButtons.WhiteButtonCap onClick={handleSubmit}
          variant="contained"
          color="primary"
          sx={{ position: 'absolute', bottom: '15px', right: '15px' }}>
          Apply
        </SldButtons.WhiteButtonCap>
      </div>
    </Box>
  );

  React.useEffect(() => {
    // Open the drawer directly when the component mounts
    setState((prev) => ({ ...prev, left: true }));
  }, []);

  //loader animation will run until response fetched from backend
  if (isLoading) {
    return (
      <div>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    );
  }

  return (
    <Drawer
      anchor="left"
      open={state["left"]}
      onClose={() => {
        setDrawerOpen(false);
        toggleDrawer("left", false);
      }}

      sx={{

        '& .MuiBackdrop-root': {
          backgroundColor: 'rgba(0, 0, 0, 0)!important', // Set the background color of the backdrop to transparent

        },
        '& .MuiPaper-root.MuiPaper-elevation': {
          left: {
            lg: isMinimized ? SIDE_NAV_MINIMIZED_WIDTH : SIDE_NAV_WIDTH,
            md: isMinimized ? SIDE_NAV_MINIMIZED_WIDTH : SIDE_NAV_WIDTH,
            sm: 0,
            xs: 0,
          },
          top: { lg: '0', md: '0', sm: '155px', xs: '155px' },
          zIndex: -1, overflow: 'hidden',
          height: { lg: '100%', md: '100%', sm: '70%', xs: 'calc(100vh - 200px)' }
        },

        '& .MuiPaper-root.MuiPaper-elevation .MuiPaper-root.MuiPaper-elevation': {
          left: 0, zIndex: 1, top: { lg: ' ', md: '', sm: '0', xs: '0' },
          height: '100%'
        },
        '& .MuiButtonBase-root': {

          '& rect': {
            fill: '#fff',
            stroke: '#fff;'
          }

        },
        '& .MuiAccordionDetails-root .MuiBox-root': { marginLeft: 0 },
        '& .MuiAccordionSummary-root': { borderBottom: '1px solid rgba(255,255,255,.3)', borderTop: '1px solid rgba(255,255,255,.3)' },
        '& .MuiAccordionSummary-content': { margin: '2px 0!important' },
        '& .MuiAccordionSummary-root.Mui-expanded': { minHeight: '25px', margin: '0px 0' },
        '& .MuiButtonBase-root.MuiCheckbox-root': { padding: '4px' },
        '& .MuiFormControlLabel-root .MuiTypography-root': { paddingLeft: 0 },
        '& .MuiAccordionSummary-root svg path': { fill: '#fff!important' },
        '& .MuiButtonBase-root.MuiCheckbox-root.Mui-checked': { color: '#fff' },
        '& .MuiAccordionSummary-content .MuiTypography-root': { fontSize: '12px' },
        '& .MuiAccordionDetails-root .MuiTypography-root': { fontSize: '12px' },
        '& .MuiList-root .MuiPaper-root.MuiPaper-elevation': { borderRadius: '0!important' }

      }}

    >
      {list("left")}
    </Drawer>
  );
}
