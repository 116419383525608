/**
 * @Author: Anandp
 * @Date:   2024-04-17 18:40:50
 * @Last Modified by:   Anandp
 * @Last Modified time: 2024-12-23 22:05:23
 */
import axios from "axios";
import { Url } from "src/config";

const HRAnalyticsApiService = {

    getResults: async (accessToken, teamID) => {
        try {
            let apiUrl = `${Url}/customer/hranalytics/getresult`;
            
            // Check if teamID is not undefined, NaN, null, or an empty array
            // eslint-disable-next-line
            if (teamID != undefined && teamID !== null && teamID.length !== 0) {
                apiUrl += `?teamId=${teamID}`;
            } 
    
            const response = await axios.get(apiUrl, {
                headers: {
                    "access-token": accessToken,
                },
            });
            return response.data;
        } catch (error) {
            return error;
        }
    },
    getSectorResults: async (accessToken, teamID) => {
        try {
            let apiUrl = `${Url}/customer/hranalytics/getsectorresult?range=${teamID}`;

            const response = await axios.get(apiUrl, {
                headers: {
                    "access-token": accessToken,
                },
            });
            return response.data;
        } catch (error) {
            return error;
        }
    },
    getQuestions: async (accessToken) => {
        try {
            let apiUrl = `${Url}/customer/orgnarratives/getquestions`;
            
            const response = await axios.get(apiUrl, {
                headers: {
                    "access-token": accessToken,
                },
            });
            return response.data;
        } catch (error) {
            return error;
        }
    },
    checkTeamLeader: async (accessToken,teamid) => {
        try {
            let apiUrl = `${Url}/customer/orgnarratives/checkteamleader?teamid=${teamid}`;
            
            const response = await axios.get(apiUrl, {
                headers: {
                    "access-token": accessToken,
                },
            });
            return response.data;
        } catch (error) {
            return error;
        }
    },
    checkLowMojo: async (accessToken,invite_id) => {
        try {
            let apiUrl = `${Url}/customer/orgnarratives/checkLowMojo?invite_id=${invite_id}`;
            
            const response = await axios.get(apiUrl, {
                headers: {
                    "access-token": accessToken,
                },
            });
            return response.data;
        } catch (error) {
            return error;
        }
    },


    // createPermission : async (accessToken,body) => {

    //     try {
    //         const response = await axios.post(`${Url}/customer/mtpsettings/usersAccessCreate`,body,{headers:{
    //             "access-token": accessToken,
    //         },})
    //         return response.data 
    //     } catch (error) {
    //         return error
    //     }

    // },
}
export default HRAnalyticsApiService